import {
    appAction,
    telegramAction,
    boostAction,
    friendAction,
    missionAction,
    paymentAction,
    gameAction,
    withdrawAction,
    dailyAction,
} from '../store/reducers';
import { request } from './index';
import { displayNotification } from './common';
import { toFixed } from './common';
import axios from 'axios';
import log from 'loglevel';
import toast from 'react-hot-toast';

const botUrl = process.env.REACT_APP_BOT;
const coinname = process.env.REACT_APP_COIN_NAME;

const sendUserInfo = async (initData, refferal, dispatch) => {
    try {
        if (!initData) {
            log.error('Telegram data is not valid:');
            dispatch(appAction.setAppPrepared(true));
            return;
        }
        const url = refferal ? `/init?refers_to=${refferal}` : '/init';
        const response = await request.post(
            url,
            { initData: initData },
            {},
            (callback) => {
                if (!callback) {
                    log.warn(
                        'Failed to fetch data: No callback data received.'
                    );
                    return;
                }
                dispatch(telegramAction.setUserInfo(callback));
            },
            (error) => {
                if (error.response && error.response.status === 500) {
                    dispatch(
                        appAction.setError({
                            description:
                                'Our app is currently undergoing technical maintenance',
                            code: 'Please be patient',
                        })
                    );
                } else if (error.response && error.response.status === 429) {
                    dispatch(
                        appAction.setError({
                            description:
                                'Our app is currently undergoing technical maintenance',
                            code: 'Please be patient',
                        })
                    );
                }
            }
        );
        if (response && response.status === 500) {
            return response;
        }
    } catch (err) {
        log.error('Error: ', err);
    }
};

const sendUserClaim = async (initData, dispatch) => {
    try {
        if (!initData) return;
        await request.post(
            '/claim',
            { initData: initData },
            {},
            (callback) => {
                if (!callback) {
                    log.warn(
                        'Failed to fetch data: No callback data received.'
                    );
                    return;
                }
                dispatch(telegramAction.setUserInfo(callback));
                toast.success(`Yay, new ${coinname} received!`);
            },
            (error) => {
                log.error('Error: ', error);
            }
        );
    } catch (err) {
        log.error('Error: ', err);
    }
};

const getUserBoosts = async (initData, dispatch) => {
    try {
        if (!initData) return;
        await request.get(
            '/boosts',
            { initData: initData },
            (callback) => {
                if (!callback) {
                    log.warn(
                        'Failed to fetch data: No callback data received.'
                    );
                    return;
                }
                dispatch(boostAction.setBoosts(callback));
                if (Array.isArray(callback)) {
                    let updatedBoosts = callback.map((boost) => {
                        return {
                            ...boost,
                            loading: false,
                        };
                    });
                    dispatch(boostAction.setBoosts(updatedBoosts));
                }
            },
            (error) => {
                log.error('Error: ', error);
            }
        );
    } catch (err) {
        log.error('Error: ', err);
    }
};

const purchaseUserBoost = async (id, initData, userInfo, dispatch) => {
    try {
        if (!initData || !id) return;
        const result = await request.post(
            `/boosts/${id}`,
            { initData: initData },
            {},
            (callback) => {
                if (!callback) {
                    log.warn(
                        'Failed to fetch data: No callback data received.'
                    );
                    return;
                }
                sendUserInfo(initData, null, dispatch).then(() => {
                    if (Array.isArray(callback.data)) {
                        let updatedBoosts = callback.data.map((boost) => {
                            return {
                                ...boost,
                                loading: false,
                                disabled: false,
                            };
                        });
                        dispatch(boostAction.setBoosts(updatedBoosts));
                    }
                });
                toast.success(`Upgraded your boost!`);
            },
            (error) => {
                log.error('Error: ', error);
            }
        );
        return result;
    } catch (err) {
        log.error('Error: ', err);
    }
};

const sendCompleteTask = async (initData, userInfo, dispatch, id, hashKey) => {
    try {
        if (!initData) return;
        const formData = new FormData();

        if (hashKey) {
            formData.append('hash', hashKey);

            const result = await request.post(
                `/missions/${id}`,
                { initData: initData },
                formData,
                (callback) => {
                    if (!callback) {
                        log.warn(
                            'Failed to fetch data: No callback data received.'
                        );
                        return;
                    }
                    const balance = callback.balance;
                    const newUserInfo = {
                        ...userInfo,
                        balance: balance,
                    };
                    dispatch(telegramAction.setUserInfo(newUserInfo));
                    dispatch(missionAction.setTasks(callback.data));
                },
                (error) => {
                    console.error('Error: ', error);
                }
            );
            return result;
        } else {
            const result = await request.post(
                `/missions/${id}`,
                { initData: initData },
                {},
                (callback) => {
                    if (!callback) {
                        log.warn(
                            'Failed to fetch data: No callback data received.'
                        );
                        return;
                    }
                    const balance = callback.balance;
                    const newUserInfo = {
                        ...userInfo,
                        balance: balance,
                    };
                    dispatch(telegramAction.setUserInfo(newUserInfo));
                    dispatch(missionAction.setTasks(callback.data));
                },
                (error) => {
                    log.error('Error: ', error);
                }
            );
            return result;
        }
    } catch (err) {
        log.error('Error: ', err);
    }
};

const sendCompleteBoost = async (initData, userInfo, dispatch, id, hashKey) => {
    try {
        if (!initData) return;

        const formData = new FormData();
        if (hashKey) {
            formData.append('hash', hashKey);
        }

        formData.append('hash', hashKey);
        const result = await request.post(
            `/products/${id}`,
            { initData: initData },
            hashKey ? formData : {},
            (callback) => {
                if (!callback) {
                    log.warn(
                        'Failed to fetch data: No callback data received.'
                    );
                    return;
                }
                dispatch(boostAction.setProducts(callback));
                sendUserInfo(initData, null, dispatch);
            },
            (error) => {
                console.error('Error: ', error);
            }
        );

        return result;
    } catch (err) {
        log.error('Error: ', err);
    }
};

const sendCompleteAchievement = async (initData, userInfo, dispatch, id) => {
    try {
        if (!initData) return;
        const result = await request.post(
            `/achieves/${id}`,
            { initData: initData },
            {},
            (callback) => {
                if (!callback) {
                    log.warn(
                        'Failed to fetch data: No callback data received.'
                    );
                    return;
                }
                const balance = callback.balance;
                const newUserInfo = {
                    ...userInfo,
                    balance: balance,
                };
                dispatch(telegramAction.setUserInfo(newUserInfo));
            },
            (error) => {
                log.error('Error: ', error);
            }
        );
        return result;
    } catch (err) {
        log.error('Error: ', err);
    }
};

const fetchReferralData = async (
    page,
    initData,
    dispatch,
    append,
    oldFriends
) => {
    try {
        if (!initData) return;
        await request.get(
            `/referral?page=${page}&perPage=10`,
            { initData: initData },
            (callback) => {
                if (!callback) {
                    log.warn(
                        'Failed to fetch data: No callback data received.'
                    );
                    return;
                }

                const { friends, meta, referral_tokens, links } = callback;

                if (!friends) {
                    log.warn('Failed to fetch data: No friends data received.');
                    return;
                }

                const updatedFriends = append
                    ? [...oldFriends, ...friends]
                    : friends;

                dispatch(friendAction.setFriends(updatedFriends));
                dispatch(friendAction.setCurrentFriendsPage(meta.current_page));
                dispatch(friendAction.setFriendsLinkNext(links.next));

                if (!append) {
                    dispatch(friendAction.setTotalFriends(meta.total));
                    dispatch(friendAction.setTotalFriendsPages(meta.last_page));
                    dispatch(friendAction.setReferralTokens(referral_tokens));
                }
            },
            (error) => {
                log.error('Error: ', error);
            }
        );
    } catch (err) {
        log.error('Error: ', err);
    }
};

const fetchWithdrawData = async (
    page,
    initData,
    dispatch,
    append,
    withdraws
) => {
    try {
        if (!initData) return;
        await request.get(
            `/withdraw?page=${page}&perPage=10`,
            { initData: initData },
            (callback) => {
                if (!callback) {
                    log.warn(
                        'Failed to fetch data: No callback data received.'
                    );
                    return;
                }
                const { data, meta, links, total } = callback;

                if (!data) {
                    log.warn('No withdraw data received.');
                    return;
                }

                const updatedWithdraws = append
                    ? [...withdraws, ...data]
                    : data;

                dispatch(withdrawAction.setWithdraws(updatedWithdraws));
                dispatch(
                    withdrawAction.setCurrentWithdrawsPage(meta.current_page)
                );
                dispatch(withdrawAction.setWithdrawsLinkNext(links.next));
                dispatch(paymentAction.setTotalTons(total));
            },
            (error) => {
                log.error('Error: ', error);
            }
        );
    } catch (err) {
        log.error('Error: ', err);
    }
};

const fetchGames = async (initData, dispatch) => {
    try {
        if (!initData) return;
        await request.get(
            `/games`,
            { initData: initData },
            (callback) => {
                if (!callback) {
                    log.warn(
                        'Failed to fetch data: No callback data received.'
                    );
                    return;
                }
                dispatch(gameAction.setGames(callback));
            },
            (error) => {
                log.error('Error: ', error);
            }
        );
    } catch (err) {
        log.error('Error: ', err);
    }
};

const fetchNotifications = async (initData, dispatch) => {
    try {
        if (!initData) return;
        await request.get(
            `/notifications`,
            { initData: initData },
            (callback) => {
                if (!callback) {
                    log.warn(
                        'Failed to fetch data: No callback data received.'
                    );
                    return;
                }
                if (callback.length > 0) {
                    toast.dismiss();

                    callback.forEach((notification, index) => {
                        setTimeout(() => {
                            displayNotification(
                                notification,
                                initData,
                                dispatch
                            );
                        }, index * 1000);
                    });
                } else {
                    return;
                }
            },
            (error) => {
                log.error('Error: ', error);
            }
        );
    } catch (err) {
        log.error('Error fetching notifications:', err);
    }
};

const getUserTasks = async (initData, dispatch) => {
    try {
        if (!initData) return;
        await request.get(
            '/missions',
            { initData: initData },
            (callback) => {
                if (!callback) {
                    log.warn(
                        'Failed to fetch data: No callback data received.'
                    );
                    return;
                }
                dispatch(missionAction.setTasks(callback));
            },
            (error) => {
                log.error('Error: ', error);
            }
        );
    } catch (err) {
        log.error('Error: ', err);
    }
};

const getProducts = async (initData, dispatch) => {
    try {
        if (!initData) return;
        await request.get(
            '/products',
            { initData: initData },
            (callback) => {
                if (!callback) {
                    log.warn(
                        'Failed to fetch data: No callback data received.'
                    );
                    return;
                }
                dispatch(boostAction.setProducts(callback));
            },
            (error) => {
                log.error('Error: ', error);
            }
        );
    } catch (err) {
        log.error('Error: ', err);
    }
};

const getUserAchievements = async (initData, dispatch) => {
    try {
        if (!initData) return;
        await request.get(
            '/achieves',
            { initData: initData },
            (callback) => {
                if (!callback) {
                    log.warn(
                        'Failed to fetch data: No callback data received.'
                    );
                    return;
                }
                dispatch(missionAction.setAchieves(callback));
            },
            (error) => {
                log.error('Error: ', error);
            }
        );
    } catch (err) {
        log.error('Error: ', err);
    }
};

const sendStorageRemind = async (chatId, initData) => {
    try {
        if (!initData) return;
        const response = await request.post(
            `/referral/remind/${chatId}`,
            { initData: initData },
            (callback) => {
                if (!callback) {
                    log.warn(
                        'Failed to fetch data: No callback data received.'
                    );
                    return;
                }
                toast.success('Nice, your friend reminded!');
            },
            (error) => {
                log.error('Error: ', error);
            }
        );

        if (response.data.ok) {
            toast.success('Remind was sended!');
        } else {
            log.error('Error: ', 'message not sended');
        }
    } catch (err) {
        log.error('Error: ', err);
    }
};

const getTonInfo = async (initData, dispatch) => {
    try {
        if (!initData) return;
        const response = await axios.get(
            'https://tonapi.io/v2/rates?tokens=ton&currencies=usd'
        );

        if (!response) {
            log.error('Some error occured during ton info receiving');
            return;
        }
        const rate = response.data.rates.TON.prices.USD;
        const rateFixed = toFixed(rate, 6);
        dispatch(paymentAction.setTonAmount(parseFloat(rateFixed)));
        return rateFixed;
    } catch (err) {
        log.error('Error: ', err);
        return;
    }
};

const buyCheckinWithTgStars = async (id, currentTaskId, init_data, amount) => {
    try {
        const data = {
            amount: amount,
            action: 'buy_checkin',
            user_id: id,
            mission_id: currentTaskId,
            init_data: init_data,
        };

        const response = await axios.post(`${botUrl}/buy_stars`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response) {
            return response.data;
        } else {
            toast('Cant open stars payment');
            return;
        }
    } catch (err) {
        log.error('Error: ', err);
        return;
    }
};

const buyProductWithTgStars = async (id, currentBoostId, init_data, amount) => {
    try {
        const data = {
            amount: amount,
            action: 'buy_product',
            user_id: id,
            boost_id: currentBoostId,
            init_data: init_data,
        };

        const response = await axios.post(`${botUrl}/buy_stars`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response) {
            toast('Cant open stars payment');
            return;
        }
        return response.data;
    } catch (err) {
        log.error('Error: ', err);
        return;
    }
};

const buyTicketWithTgStars = async (id, currentBoostId, init_data, amount) => {
    try {
        const data = {
            amount: amount,
            action: 'buy_product',
            user_id: id,
            boost_id: currentBoostId,
            init_data: init_data,
        };

        const response = await axios.post(`${botUrl}/buy_stars`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response) {
            toast('Cant open stars payment');
            return;
        }
        return response.data;
    } catch (err) {
        log.error('Error: ', err);
        return;
    }
};

const sendPurchaseTicket = async (
    initData,
    userInfo,
    dispatch,
    id,
    hashKey
) => {};

const claimDailyReward = async (initData, dispatch) => {
    try {
        if (!initData) return;
        await request.post(
            '/daily-reward',
            { initData: initData },
            {},
            (callback) => {
                if (!callback) {
                    log.warn(
                        'Failed to fetch data: No callback data received.'
                    );
                    return;
                }
                dispatch(telegramAction.setUserInfo(callback));
                toast.success(`Yay, new ${coinname} and Tickets received!`);
                setTimeout(() => {
                    getDailyRewards(initData, dispatch);
                }, 1000);
            },
            (error) => {
                log.error('Error: ', error);
            }
        );
    } catch (err) {
        log.error('Error: ', err);
    }
};

const getDailyRewards = async (initData, dispatch) => {
    try {
        if (!initData) return;
        await request.get(
            '/daily-reward',
            { initData: initData },
            (callback) => {
                if (!callback) {
                    log.warn(
                        'Failed to fetch data: No callback data received.'
                    );
                    return;
                }
                const sortedRewards = callback.data.sort(
                    (a, b) => a.day_in_streak - b.day_in_streak
                );

                const seenStreaks = new Set();
                const filteredRewards = sortedRewards.filter((item) => {
                    if (!seenStreaks.has(item.day_in_streak)) {
                        seenStreaks.add(item.day_in_streak);
                        return true;
                    }
                    return false;
                });

                const reindexedRewards = filteredRewards.map((item, index) => ({
                    ...item,
                    day_in_streak: index + 1,
                }));
                dispatch(dailyAction.setDailyRewards(reindexedRewards));
                dispatch(
                    dailyAction.setDailyRewardStreak(callback.daily_streak)
                );
            },
            (error) => {
                log.error('Error: ', error);
            }
        );
    } catch (err) {
        log.error('Error: ', err);
    }
};

const sendUserWithdrawalRequest = async (tonAddress, initData, dispatch) => {
    try {
        if (!initData) return;
        const formData = new FormData();

        if (tonAddress) {
            formData.append('wallet', tonAddress);

            await request.post(
                '/withdraw',
                { initData: initData },
                formData,
                (callback) => {
                    if (!callback) {
                        log.warn(
                            'Failed to fetch data: No callback data received.'
                        );
                        return;
                    }
                    dispatch(withdrawAction.setWithdraws(callback.data));
                    dispatch(
                        withdrawAction.setCurrentWithdrawsPage(
                            callback.meta.current_page
                        )
                    );
                    dispatch(
                        withdrawAction.setWithdrawsLinkNext(callback.links.next)
                    );
                    toast.success(
                        `Request for tons is submited,\nit can take a few days!`,
                        { duration: 6000 }
                    );
                },
                (error) => {
                    log.error('Error: ', error);
                }
            );
        }
    } catch (err) {
        log.error('Error: ', err);
    }
};

const fetchSettings = async (initData, dispatch) => {
    try {
        if (!initData) return;
        await request.get(
            '/settings',
            { initData: initData },
            (callback) => {
                if (!callback) {
                    log.warn(
                        'Failed to fetch data: No callback data received.'
                    );
                    return;
                }
                dispatch(appAction.setSettings(callback));
            },
            (error) => {
                log.error('Error: ', error);
            }
        );
    } catch (err) {
        log.error('Error: ', err);
    }
};

export {
    sendUserInfo,
    sendUserClaim,
    buyCheckinWithTgStars,
    buyProductWithTgStars,
    buyTicketWithTgStars,
    sendCompleteTask,
    sendCompleteAchievement,
    getUserBoosts,
    getUserTasks,
    getUserAchievements,
    getTonInfo,
    getProducts,
    getDailyRewards,
    purchaseUserBoost,
    fetchReferralData,
    fetchWithdrawData,
    fetchNotifications,
    sendStorageRemind,
    sendCompleteBoost,
    sendPurchaseTicket,
    claimDailyReward,
    fetchGames,
    fetchSettings,
    sendUserWithdrawalRequest,
};
