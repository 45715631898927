import React from 'react';
import TonIcon from '../../assets/components/TonIcon/TonIcon.svg';
import './WithdrawHistoryInfo.scss';

const WithdrawHistoryInfo = ({ totalTons }) => {
    return (
        <div className="whi-info-container">
            <div className="whi-info">
                <img className="whi-icon" src={TonIcon} alt="ton icon"></img>
                <span className="whi-amount">{parseFloat(totalTons).toFixed(4)}</span>
                <span className="whi-name">TON</span>
                <span className="whi-action">Withdrawn</span>
            </div>
            <div className="whi-description">
                <span className="whi-description-text">
                    Here you can find whole list of your TON withdrawals to your
                    external wallet
                </span>
            </div>
        </div>
    );
};

export default WithdrawHistoryInfo;
