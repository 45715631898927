import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    games: [],
};

export const gameSlice = createSlice({
    name: 'game',
    initialState,
    reducers: {
        setGames: (state, action) => {
            state.games = action.payload;
        },
    },
});

export const { setGames } = gameSlice.actions;

export default gameSlice.reducer;
