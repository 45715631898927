import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SuspenseImage from '../SuspenseImage/SuspenseImage';
import Skeleton from 'react-loading-skeleton';
import ProgressBar from '../ProgressBar/ProgressBar';
import Button from '../Button/Button';
import storageIcon from '../../assets/components/StorageIcon/StorageIcon.svg';
import starIcon from '../../assets/components/StarIcon/RigelWhite.svg';
import Upgrades from '../Upgrades/Upgrades';
import { sendUserClaim } from '../../tools/requests';
import useTimeLeft from '../../hooks/useTimeLeft';
import useProgress from '../../hooks/useProgress';
import { fetchNotifications } from '../../tools/requests';
import './Storage.scss';

const Storage = ({
    tokensPerHour,
    balance,
    claim,
    setOpenCollectorModal,
    setOpenIncomeModal,
    setOpenX2Modal,
}) => {
    const dispatch = useDispatch();
    const timeLeft = useTimeLeft(claim.finished_at);
    const progressPercent = useProgress(claim.created_at, claim.finished_at);

    const userData = window.Telegram.WebApp.initData;

    const isPaymentLoading = useSelector(
        (state) => state.payment.isPaymentLoading
    );
    const products = useSelector((state) => state.boost.products);
    const autoCollectorItem = products.AutoClaim;
    const isAutoCollectorActive = autoCollectorItem.purchased ? true : false;
    const incomeIncreaseItem = products.RateMining;
    const isIncomeIncreaseActive = incomeIncreaseItem.purchased ? true : false;

    const colors = ['#ffc000', '#ff3b3b', '#ff8400'];
    const bubbles = 25;

    const [isBtnActive, setIsBtnActive] = useState(false);
    const [hasClicked, setHasClicked] = useState(false);
    const [buttonStatus, setButtonStatus] = useState('Loading...');

    const explode = (x, y) => {
        let particles = [];
        let ratio = window.devicePixelRatio;
        let c = document.createElement('canvas');
        let ctx = c.getContext('2d');

        c.style.position = 'absolute';
        c.style.left = x - 100 + 'px';
        c.style.top = y - 100 + 'px';
        c.style.pointerEvents = 'none';
        c.style.width = 200 + 'px';
        c.style.height = 200 + 'px';
        c.style.zIndex = 100;
        c.width = 200 * ratio;
        c.height = 200 * ratio;
        document.body.appendChild(c);

        for (var i = 0; i < bubbles; i++) {
            particles.push({
                x: c.width / 2,
                y: c.height / 2,
                radius: r(20, 30),
                color: colors[Math.floor(Math.random() * colors.length)],
                rotation: r(0, 360, true),
                speed: r(8, 12),
                friction: 0.9,
                opacity: r(0, 0.5, true),
                yVel: 0,
                gravity: 0.1,
            });
        }

        render(particles, ctx, c.width, c.height);
        setTimeout(() => document.body.removeChild(c), 1000);
    };

    const render = (particles, ctx, width, height) => {
        requestAnimationFrame(() => render(particles, ctx, width, height));
        ctx.clearRect(0, 0, width, height);

        particles.forEach((p, i) => {
            p.x += p.speed * Math.cos((p.rotation * Math.PI) / 180);
            p.y += p.speed * Math.sin((p.rotation * Math.PI) / 180);

            p.opacity -= 0.01;
            p.speed *= p.friction;
            p.radius *= p.friction;
            p.yVel += p.gravity;
            p.y += p.yVel;

            if (p.opacity < 0 || p.radius < 0) return;

            ctx.beginPath();
            ctx.globalAlpha = p.opacity;
            ctx.fillStyle = p.color;
            ctx.arc(p.x, p.y, p.radius, 0, 2 * Math.PI, false);
            ctx.fill();
        });

        return ctx;
    };

    const r = (a, b, c) =>
        parseFloat(
            (Math.random() * ((a ? a : 1) - (b ? b : 0)) + (b ? b : 0)).toFixed(
                c ? c : 0
            )
        );

    const handleBtnClick = (e) => {
        if (isPaymentLoading) {
            e.preventDefault();
            return;
        } else if (isBtnActive && !hasClicked) {
            const randomChance = Math.random();

            if (randomChance >= 0.5) {
                setOpenX2Modal(true);
            } else {
                setButtonStatus('Loading...');
                sendUserClaim(userData, dispatch);
                setHasClicked(true);
                const confettiElements = document.querySelectorAll(
                    '.claim-btn.active .particle'
                );
                confettiElements.forEach((element) => {
                    element.parentNode.removeChild(element);
                });
                explode(e.pageX, e.pageY);
                setButtonStatus('Claimed!');
                setIsBtnActive(false);
            }
        }
    };

    const renderButtonContent = () => {
        /* if (isPaymentLoading) {
            return 'Loading';
        } else*/
        if (buttonStatus === 'Claim') {
            if (timeLeft !== '0') {
                return (
                    <div className="claim-btn-container">
                        <div className="claim-btn-holder">
                            <img
                                className="claim-star-icon"
                                src={starIcon}
                                alt="star coin icon"
                            />
                            <span className="claim-btn-text">Claim</span>
                        </div>
                        <div className="claim-btn-time">{timeLeft}</div>
                    </div>
                );
            } else {
                return (
                    <>
                        <img
                            className="claim-star-icon"
                            src={starIcon}
                            alt="star coin icon"
                        />
                        <span className="claim-btn-text">Claim</span>
                    </>
                );
            }
        } else if (timeLeft === '0') {
            return 'Loading';
        } else {
            return timeLeft;
        }
    };

    useEffect(() => {
        if (isBtnActive) {
            setButtonStatus('Claim');
        }
    }, [isBtnActive]);

    useEffect(() => {
        if (progressPercent >= 80 && progressPercent <= 100) {
            setIsBtnActive(true);
        } else if (progressPercent >= 0 && progressPercent <= 80) {
            setButtonStatus('Claimed!');
            setIsBtnActive(false);
        } else {
            setButtonStatus('Loading...');
        }
    }, [progressPercent]);

    useEffect(() => {
        if (
            isAutoCollectorActive &&
            timeLeft === '0' &&
            progressPercent === 100
        ) {
            const timeoutId = setTimeout(() => {
                fetchNotifications(userData, dispatch);
            }, 1000);

            return () => clearTimeout(timeoutId);
        }
    }, [isAutoCollectorActive, timeLeft, progressPercent, userData, dispatch]);

    return (
        <div className="storage">
            <div className="storage-wrapper">
                <div className="storage-shop"></div>
                <div className="storage-container">
                    <div className="main-container">
                        <div className="storage-img">
                            <SuspenseImage
                                className="img"
                                src={storageIcon}
                                alt="storage treasure icon"
                                width="7.2rem"
                                height="7.2rem"
                                paddingBottom="0.5rem"
                            />
                        </div>
                        <div className="info-container">
                            <div className="date">
                                {(timeLeft === '0'
                                    ? 'Reward Available'
                                    : 'Mining...') || <Skeleton />}
                            </div>
                            <div className="time-container">
                                <span className="time">
                                    <SuspenseImage
                                        src={starIcon}
                                        width="2rem"
                                        height="2rem"
                                        alt="star coin icon"
                                    ></SuspenseImage>
                                    <span>
                                        {`${parseFloat(
                                            tokensPerHour
                                        ).toString()} / hour` || <Skeleton />}
                                    </span>
                                </span>
                            </div>
                            <ProgressBar
                                progress={progressPercent}
                                isIncomeIncreaseActive={isIncomeIncreaseActive}
                            />
                        </div>
                    </div>
                    <div className="claim-button-container">
                        <Button
                            onClick={handleBtnClick}
                            className={`claim-btn ${
                                isPaymentLoading
                                    ? 'disabled clicked'
                                    : isBtnActive
                                    ? 'glowing'
                                    : 'disabled clicked'
                            }${hasClicked ? ' clicked' : ''}`}
                            disabled={isPaymentLoading}
                        >
                            {renderButtonContent()}
                        </Button>
                    </div>
                </div>
                <Upgrades
                    setOpenCollectorModal={setOpenCollectorModal}
                    setOpenIncomeModal={setOpenIncomeModal}
                    isAutoCollectorActive={isAutoCollectorActive}
                    isIncomeIncreaseActive={isIncomeIncreaseActive}
                />
            </div>
        </div>
    );
};

export default Storage;
