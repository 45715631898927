import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    friends: [],
    totalFriends: 0,
    linkFriendsNext: null,
    remindedFriends: [],
    currentFriendsPage: 0,
    totalFriendsPages: 0,
    referralTokens: 0,
};

export const friendSlice = createSlice({
    name: 'friend',
    initialState,
    reducers: {
        setFriends: (state, action) => {
            state.friends = action.payload;
        },
        setTotalFriends: (state, action) => {
            state.totalFriends = action.payload;
        },
        setFriendsLinkNext: (state, action) => {
            state.linkFriendsNext = action.payload;
        },
        setCurrentFriendsPage: (state, action) => {
            state.currentFriendsPage = action.payload;
        },
        setTotalFriendsPages: (state, action) => {
            state.totalFriendsPages = action.payload;
        },
        setReferralTokens: (state, action) => {
            state.referralTokens = action.payload;
        },
        setRemindedFriends: (state, action) => {
            state.remindedFriends.push(action.payload);
        },
    },
});

export const {
    setFriends,
    setTotalFriends,
    setFriendsLinkNext,
    setCurrentFriendsPage,
    setTotalFriendsPages,
    setReferralTokens,
    setRemindedFriends,
} = friendSlice.actions;

export default friendSlice.reducer;
