import React, { useEffect, useState } from 'react';
import { useTonAddress } from '@tonconnect/ui-react';
import { buyCheckinWithTgStars } from '../../tools/requests';
import TonAddressInfo from '../../components/TonAddressInfo/TonAddressInfo';
import TonSendTransaction from '../../components/TonSendTransaction/TonSendTransaction';
import Button from '../../components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { missionAction } from '../../store/reducers';
import { convertDollarToTon } from '../../tools/common';
import toast from 'react-hot-toast';
import './PaymentMethodModal.scss';

const PaymentMethodModal = ({ userInfo, currentTaskId, onClose }) => {
    const address = useTonAddress();
    const dispatch = useDispatch();
    const tg = window.Telegram.WebApp;
    const initData = useSelector((state) => state.telegram.initData);
    const userTasks = useSelector((state) => state.mission.tasks);
    const tonRate = useSelector((state) => state.payment.tonAmount);

    const [tonAmount, setTonAmount] = useState(0);
    const [starsAmount, setStarsAmount] = useState(0);
    const [dollarAmount, setDollarAmount] = useState(0);
    const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);

    useEffect(() => {
        const checkInTaks = userTasks.Daily.find(
            (task) => task.type === 'CheckIn'
        );
        const tonValue = convertDollarToTon(checkInTaks.price.USD, tonRate);
        const starsValue = checkInTaks.price.XTR;
        const dollarValue = checkInTaks.price.USD;
        setDollarAmount(dollarValue);
        setTonAmount(tonValue);
        setStarsAmount(starsValue);
    }, [tonRate, userTasks]);

    const handleStarsPayment = async () => {
        if (isInvoiceOpen) {
            toast('Only one payment can be processed at the same time.');
            return;
        }

        setIsInvoiceOpen(true);

        const result = await buyCheckinWithTgStars(
            userInfo.chat_id,
            currentTaskId,
            initData,
            starsAmount
        );

        if (result && result.url) {
            tg.openInvoice(result.url, (invoiceStatus) => {
                if (invoiceStatus === 'paid') {
                    setIsInvoiceOpen(false);
                    const taskCategory = Object.keys(userTasks).find(
                        (category) =>
                            userTasks[category].some(
                                (task) => task.id === currentTaskId
                            )
                    );
                    if (taskCategory) {
                        const updatedTasks = userTasks[taskCategory].map(
                            (task) =>
                                task.id === currentTaskId
                                    ? { ...task, status: 'Started' }
                                    : task
                        );
                        dispatch(
                            missionAction.setTasks({
                                ...userTasks,
                                [taskCategory]: updatedTasks,
                            })
                        );
                    }
                    onClose();
                }
                if (invoiceStatus === 'cancelled') {
                    setIsInvoiceOpen(false);
                }
                if (invoiceStatus === 'failed') {
                    setIsInvoiceOpen(false);
                }
            });
        } else {
            console.error('Invoice URL is invalid', result);
            setIsInvoiceOpen(false);
        }
    };

    return (
        <div className="modal-container">
            {address && (
                <div className="ton-info">
                    <TonAddressInfo />
                </div>
            )}
            <div className="payment-info">
                <div className="info-value">
                    <span className="before-payment">
                        {dollarAmount} $ = {tonAmount} TON
                    </span>
                </div>
            </div>
            <TonSendTransaction
                tonAmount={tonAmount}
                currentTaskId={currentTaskId}
                userId={userInfo.chat_id}
                itemType={'mission'}
                itemId={currentTaskId}
                onClose={onClose}
                userTasks={userTasks}
                className="payment-btn glowing"
            />
            <Button
                className="payment-btn glowing"
                onClick={handleStarsPayment}
            >
                Pay {starsAmount} Stars
            </Button>
        </div>
    );
};

export default PaymentMethodModal;
