import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    withdraws: [],
    linkWithdrawsNext: null,
    currentWithdrawsPage: 0,
};

export const withdrawSlice = createSlice({
    name: 'withdraw',
    initialState,
    reducers: {
        setWithdraws: (state, action) => {
            state.withdraws = action.payload;
        },
        setWithdrawsLinkNext: (state, action) => {
            state.linkWithdrawsNext = action.payload;
        },
        setCurrentWithdrawsPage: (state, action) => {
            state.currentWithdrawsPage = action.payload;
        },
    },
});

export const { setWithdraws, setWithdrawsLinkNext, setCurrentWithdrawsPage } =
    withdrawSlice.actions;

export default withdrawSlice.reducer;
