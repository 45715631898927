import React from 'react';

class Settings extends React.PureComponent {
    constructor(props) {
        super(props);

        // Initialize the state based on the passed config prop
        this.state = {
            ...props.config.properties, // Spread in the properties from the config
            particles: props.config.particles,
            emissionRate: props.config.emissionRate,
            changed: false,
        };

        // Bind the onChange method to this
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        // Add the onChange listener to the config's listeners array
        this.props.config.listeners.push(this.onChange);
    }

    componentWillUnmount() {
        // Clean up the listener when the component unmounts
        const index = this.props.config.listeners.indexOf(this.onChange);
        if (index > -1) {
            this.props.config.listeners.splice(index, 1);
        }
    }

    onChange(prop, val) {
        // Update the state when a config property changes
        this.setState({ [prop]: val, changed: true });

        // Reset the 'changed' state after a brief timeout
        clearTimeout(this.changeTimeout);
        this.changeTimeout = setTimeout(() => {
            this.setState({ changed: false });
        }, 0);
    }

    render() {
        // If the state has changed, render nothing temporarily
        return this.state.changed ? null : this.props.children(this.state);
    }
}

export default Settings;
