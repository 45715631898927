import React, { Suspense } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ROUTES } from './routes/index';
import Navbar from './layouts/Navbar/Navbar';
import NotFound from './pages/NotFound/NotFound';
import Main from './pages/Main/Main';
import Missions from './pages/Missions/Missions';
import Boost from './pages/Boost/Boost';
import Friends from './pages/Friends/Friends';
import Apps from './pages/Apps/Apps';
import WithdrawHistory from './pages/WithdrawHistory/WithdrawHistory';
import { AnimatePresence } from 'framer-motion';
//import Guild from './pages/Guild/Guild';
//import GuildBoost from './pages/GuildBoost/GuildBoost';
//import GuildList from './pages/GuildList/GuildList';
//import * as Sentry from '@sentry/react';
//import { trackPageView } from './analytics';
//const SentryRoute = Sentry.withSentryRouting(Route);

export default function RouterSwitch() {
    const location = useLocation();

    /*useEffect(() => {
        trackPageView(location.pathname + location.search);
    }, [location]);*/

    const navbarPaths = [
        ROUTES.MAIN,
        ROUTES.MISSIONS,
        ROUTES.BOOST,
        ROUTES.FRIENDS,
        ROUTES.APPS,
        ROUTES.WITHDRAW_HISTORY,
    ];

    const showNavbar = navbarPaths.includes(location.pathname);

    return (
        <>
            <Suspense fallback={null}>
                <AnimatePresence mode="wait">
                    <Switch location={location} key={location.pathname}>
                        <Route exact path={ROUTES.MAIN}>
                            <Main />
                        </Route>
                        <Route exact path={ROUTES.MISSIONS}>
                            <Missions />
                        </Route>
                        <Route exact path={ROUTES.BOOST}>
                            <Boost />
                        </Route>
                        <Route exact path={ROUTES.FRIENDS}>
                            <Friends />
                        </Route>
                        <Route exact path={ROUTES.WITHDRAW_HISTORY}>
                            <WithdrawHistory />
                        </Route>
                        <Route exact path={ROUTES.APPS}>
                            <Apps />
                        </Route>
                        <Route exact path={ROUTES.NOT_FOUND}>
                            <NotFound />
                        </Route>
                    </Switch>
                </AnimatePresence>
            </Suspense>
            {showNavbar && (
                <div
                    className="router-footer"
                    style={{
                        position: 'absolute',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '0 1rem 0',
                        height: '100%',
                        justifyContent: 'flex-end',
                        bottom: '0',
                        marginBottom: 'env(safe-area-inset-bottom)',
                        pointerEvents: 'none',
                    }}
                >
                    <Navbar />
                </div>
            )}
        </>
    );
}
