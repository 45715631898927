import {
    useTonConnectModal,
    useIsConnectionRestored,
    useTonConnectUI,
    useTonWallet,
} from '@tonconnect/ui-react';
import { sendUserWithdrawalRequest } from '../../tools/requests';
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import Button from '../Button/Button';
import './TonSendWithdrawTransaction.scss';
import { useDispatch } from 'react-redux';

const TonSendWithdrawTransaction = ({
    tonAmount,
    address,
    onClose,
    className,
    children,
}) => {
    const initData = window.Telegram.WebApp.initData;

    const dispatch = useDispatch();
    const wallet = useTonWallet();
    const isConnectionRestored = useIsConnectionRestored();
    const [tonConnectUI] = useTonConnectUI();
    const { close } = useTonConnectModal();

    const [isInProgress, setIsInProgress] = useState(false);

    useEffect(() => {
        setBtnContent(getTxButtonContent());
    }, [isConnectionRestored, isInProgress, wallet]);

    const getTxButtonContent = () => {
        if (!isConnectionRestored) return 'Loading...';
        if (isInProgress) return 'In progress...';
        return wallet ? `Request ${tonAmount} TON` : `Connect TON`;
    };

    const [btnContent, setBtnContent] = useState(getTxButtonContent());

    const onSendTx = async () => {
        try {
            if (!wallet) {
                tonConnectUI.openModal();
                return;
            } else {
                setIsInProgress(true);
                
                await sendUserWithdrawalRequest(address, initData, dispatch);
                close();
                onClose();
            }
        } catch (error) {
            toast.error('Some problems during request.');
            close();
            onClose();
        } finally {
            setIsInProgress(false);
        }
    };

    return (
        <div className="ton-btn-container">
            <Button
                className={className}
                disabled={!isConnectionRestored || isInProgress}
                onClick={onSendTx}
            >
                {btnContent}
            </Button>
            {children}
        </div>
    );
};

export default TonSendWithdrawTransaction;
