import React, { useState } from 'react';
import starGradientIcon from '../../assets/components/StarIcon/RigelLogo.svg';
import rigelWhite from '../../assets/components/StarIcon/RigelWhite.svg';
import spaceAstronaut from '../../assets/components/TutorialIcons/SpaceAstronautIcon.png';
import spacePlanet from '../../assets/components/StorageIcon/StorageIcon.svg';
import Storage from '../../assets/components/BoostIcon/Storage.svg';
import Mining from '../../assets/components/BoostIcon/Mining.svg';
import ManyAstronauts from '../../assets/components/TutorialIcons/ManyAstronauts.svg';
import Rocket from '../../assets/components/TutorialIcons/RocketIcon.svg';
import Reward from '../../assets/components/TutorialIcons/Reward.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import SuspenseImage from '../../components/SuspenseImage/SuspenseImage';
import Skeleton from 'react-loading-skeleton';
import './Tutorial.scss';

const Tutorial = ({ onClose }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [translateX, setTranslateX] = useState(0);

    const pages = [
        {
            content: (
                <div className="tutorial-page-one">
                    <div className="tutorial-main-info">
                        <span className="tutorial-label">
                            Claim Your Rewards
                        </span>
                        <span className="tutorial-description">
                            Enter the app regularly to claim your rewards and
                            maximize your coin collection. Don’t let your
                            storage fill up— stay active and keep earning!
                        </span>
                    </div>

                    <div className="tutorial-container">
                        <div className="tutorial-claim-container">
                            <span className="tutorial-claim-label">
                                In Storage:
                            </span>
                            <span className="tutorial-claim-value">
                                <img
                                    className="coin-gradient"
                                    src={starGradientIcon}
                                    alt=""
                                ></img>
                                <span className="coin">50.5253</span>
                            </span>
                            <button className="tutorial-claim-button">
                                <img
                                    className="coin-white"
                                    src={rigelWhite}
                                    alt=""
                                ></img>
                                <span className="claim-text">Claim</span>
                            </button>
                        </div>
                        <div className="tutorial-icons-container">
                            <img
                                className="astronaut"
                                src={spaceAstronaut}
                                alt="Intro"
                            />
                            <img
                                className="planet"
                                src={spacePlanet}
                                alt=""
                            ></img>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div className="tutorial-page-two">
                    <div className="tutorial-main-info">
                        <span className="tutorial-label">
                            Enhance and Upgrade
                        </span>
                        <span className="tutorial-description">
                            Boost your progress by upgrading your storage and
                            mining speed. These enhancements let you earn more
                            coins faster and with less effort.
                        </span>
                    </div>
                    <div className="tutorial-container">
                        <div className="achievement-info">
                            <label className="achievement-label">
                                Upgrade Stats
                            </label>
                            <div className="description-block">
                                <span className="achievement-value">
                                    <span className="storage-info">
                                        <SuspenseImage
                                            className="counter-gem"
                                            alt="eni-dimond"
                                            src={rigelWhite}
                                            width="2rem"
                                            height="2rem"
                                            cricle="true"
                                        ></SuspenseImage>
                                        <span className="balance-coins">
                                            {0.1500 || (
                                                <Skeleton width="10rem" />
                                            )}
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className={`boost-upgrade-container`}>
                            <div className="inner-children">
                                <div className="info-container">
                                    <div className="img-container">
                                        <img
                                            className="img"
                                            src={Mining}
                                            alt=""
                                        />
                                    </div>
                                    <div className="inner-info">
                                        <div className="upgrade-info">
                                            <div className="title">
                                                <label className="upgrade-title">
                                                    Mining speed
                                                </label>
                                            </div>
                                            <div className="info">
                                                <span className="upgrade-data">
                                                    0.06 / hour
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="upgrade-btn-container">
                                    <div className="upgrade-holder">
                                        <div className="upgrade-level-holder">
                                            <div className="upgrade-additional-level">
                                                Level 1
                                            </div>
                                        </div>
                                        <div className="upgrade-coin-holder">
                                            <span className="coin-img">
                                                <img
                                                    src={rigelWhite}
                                                    alt=""
                                                ></img>
                                            </span>
                                            <span className="cost-data">
                                                0.15
                                            </span>
                                        </div>
                                    </div>
                                    <div className="upgrade">
                                        <FontAwesomeIcon
                                            className="upgrade-icon"
                                            icon={faChevronRight}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`boost-upgrade-container`}>
                            <div className="inner-children">
                                <div className="info-container">
                                    <div className="img-container">
                                        <img
                                            className="img"
                                            src={Storage}
                                            alt=""
                                        />
                                    </div>
                                    <div className="inner-info">
                                        <div className="upgrade-info">
                                            <div className="title">
                                                <label className="upgrade-title">
                                                    Storage
                                                </label>
                                            </div>
                                            <div className="info">
                                                <span className="upgrade-data">
                                                    Сlaim every 2 hours
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="upgrade-btn-container">
                                    <div className="upgrade-holder">
                                        <div className="upgrade-level-holder">
                                            <div className="upgrade-additional-level">
                                                Level 4
                                            </div>
                                        </div>
                                        <div className="upgrade-coin-holder">
                                            <span className="coin-img">
                                                <img
                                                    src={rigelWhite}
                                                    alt="star icon"
                                                ></img>
                                            </span>
                                            <span className="cost-data">
                                                2.15
                                            </span>
                                        </div>
                                    </div>
                                    <div className="upgrade">
                                        <FontAwesomeIcon
                                            className="upgrade-icon"
                                            icon={faChevronRight}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tutorial-img-container">
                        <img className="rocket-img" src={Rocket} alt=""></img>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div className="tutorial-page-three">
                    <div className="tutorial-main-info">
                        <span className="tutorial-label">
                            Grow Your Earnings with Friends
                        </span>
                        <span className="tutorial-description">
                            Boost your progress by upgrading your storage and
                            mining speed. These enhancements let you earn more
                            coins faster and with less effort.
                        </span>
                    </div>
                    <div className="tutorial-sub-description">
                        <span className="sub-description">
                            Every time your friend claims coins you get 15%
                            cashback. And 5% every time their referrals claim
                            it.
                        </span>
                    </div>
                    <div className="tutorial-footer-container">
                        <img
                            className="many-astronaut"
                            src={ManyAstronauts}
                            alt=""
                        ></img>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div className="tutorial-page-four">
                    <div className="tutorial-footer-container">
                        <img className="reward-drop" src={Reward} alt=""></img>
                    </div>
                    <div className="tutorial-main-info">
                        <span className="tutorial-label">
                            Stay Ready for Airdrops
                        </span>
                        <span className="tutorial-description">
                            Exciting airdrops are on the horizon! Keep an eye
                            out for these exclusive rewards, just for being part
                            of our community.
                        </span>
                    </div>
                </div>
            ),
        },
    ];

    const handleNext = () => {
        if (currentPage < pages.length - 1) {
            setCurrentPage(currentPage + 1);
            setTranslateX(translateX - 100); // Move left
        }
    };

    const handleBack = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
            setTranslateX(translateX + 100); // Move right
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div className="tutorial-modal">
            <div className="tutorial-indicators">
                {pages.map((_, index) => (
                    <div
                        key={index}
                        className={`indicator ${
                            index === currentPage ? 'active' : ''
                        }`}
                    ></div>
                ))}
            </div>
            <div className="tutorial-container">
                <div
                    className="tutorial-carousel"
                    style={{ transform: `translateX(${translateX}%)` }}
                >
                    {pages.map((page, index) => (
                        <div key={index} className="tutorial-slide">
                            {page.content}
                        </div>
                    ))}
                </div>
            </div>

            <div className="tutorial-buttons">
                {currentPage > 0 && (
                    <button
                        onClick={handleBack}
                        className={`tutorial-button ${
                            currentPage === 0 ? 'disabled' : ''
                        }`}
                        disabled={currentPage === 0}
                    >
                        Back
                    </button>
                )}
                <button
                    className="tutorial-button glowing"
                    onClick={
                        currentPage < pages.length - 1
                            ? handleNext
                            : handleClose
                    }
                >
                    {currentPage < pages.length - 1 ? 'Continue' : 'Got it!'}
                </button>
            </div>
        </div>
    );
};

export default Tutorial;
