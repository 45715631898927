import React, { useEffect, useState, useRef, useCallback, lazy } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { ROUTES } from '../../routes';
import {
    fetchReferralData,
    fetchWithdrawData,
    sendStorageRemind,
} from '../../tools/requests';
import { friendAction } from '../../store/reducers';
import Skeleton from 'react-loading-skeleton';
import toast from 'react-hot-toast';
import Button from '../../components/Button/Button';
import Wrapper from '../../layouts/Wrapper/Wrapper';
import StarIcon from '../../assets/components/StarIcon/RigelWhite.svg';
import tonIcon from '../../assets/components/TonIcon/TonIcon.svg';
import infoIcon from "../../assets/components/MissionIcon/InfoIcon.png";
import AnonymousIcon from '../../assets/components/Anonymous/AnonymousIcon.png';
import copyIcon from '../../assets/components/CopyIcon/Copy.svg';
import SuspenseImage from '../../components/SuspenseImage/SuspenseImage';
import historyIcon from '../../assets/components/WithdrawHistory/HistoryIcon.svg';
import ReferralWithdrawModal from '../../modals/ReferralWithdrawModal/ReferralWithdrawModal';
import Popup from '../../components/Popup/Popup';
import './Friends.scss';

const WithdrawHistory = lazy(() =>
    import('../WithdrawHistory/WithdrawHistory')
);

const coinname = process.env.REACT_APP_COIN_NAME;
const appname = process.env.REACT_APP_NAME;
const telegram_url = process.env.REACT_APP_TELEGRAM_BOT_URL;
const delay = 2000;

const Friends = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { path } = useRouteMatch();

    const userInfo = useSelector((state) => state.telegram.userInfo);
    const settings = useSelector((state) => state.app.settings);
    const currentPage = useSelector((state) => state.friend.currentFriendsPage);
    const totalPages = useSelector((state) => state.friend.totalFriendsPages);
    const referralTokens = useSelector((state) => state.friend.referralTokens);
    const friends = useSelector((state) => state.friend.friends);
    const withdraw = useSelector((state) => state.withdraw.withdraws);
    const linkNext = useSelector((state) => state.friend.linkFriendsNext);
    const totalFriends = useSelector((state) => state.friend.totalFriends);
    const remindedFriends = useSelector(
        (state) => state.friend.remindedFriends
    );

    const [localRemindedFriends, setLocalRemindedFriends] = useState([]);
    const [hasScrolled, setHasScrolled] = useState(false);
    const [openRWModal, setOpenRWModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [withdrawDisabled, setWithdrawDisabled] = useState(false);

    const initData = window.Telegram.WebApp.initData;
    const referal_id = userInfo.referral_id ? userInfo.referral_id : null;

    const observer = useRef(null);
    const lastFriendElementRef = useRef(null);
    const friendsContainerRef = useRef(null);

    const setupObserver = useCallback(() => {
        if (observer.current) observer.current.disconnect();

        if (
            linkNext !== null &&
            currentPage < totalPages &&
            lastFriendElementRef.current
        ) {
            observer.current = new IntersectionObserver(async (entries) => {
                if (entries[0].isIntersecting && !loading) {
                    setLoading(true);
                    setShowLoading(true);

                    await fetchReferralData(
                        currentPage + 1,
                        initData,
                        dispatch,
                        true,
                        friends
                    );

                    setTimeout(() => {
                        setLoading(false);
                    }, delay);
                }
            });

            observer.current.observe(lastFriendElementRef.current);
        }
    }, [
        dispatch,
        loading,
        currentPage,
        totalPages,
        initData,
        linkNext,
        friends,
    ]);

    const handleInviteClick = useCallback(() => {
        const url = `${telegram_url}?startapp=${referal_id}`;
        const text = `Join me on ${coinname} farm at ${appname} app!`;

        const deepLink = `https://t.me/share/url?url=${encodeURIComponent(
            url
        )}&text=${encodeURIComponent(text)}`;

        if (window.Telegram.WebApp) {
            window.Telegram.WebApp.openTelegramLink(deepLink);
        }
    }, [referal_id]);

    const handleOpenHistory = useCallback(async () => {
        await fetchWithdrawData(1, initData, dispatch, false, []);
        history.push(ROUTES.WITHDRAW_HISTORY);
    }, [dispatch, history, initData]);

    const copyInviteLink = useCallback(() => {
        const url = `${telegram_url}?startapp=${referal_id}`;
        navigator.clipboard.writeText(url).then(
            () => {
                toast('Referral link copied');
            },
            (err) => {
                toast.error('Failed to copy invite link.');
                console.error('Failed to copy text: ', err);
            }
        );
    }, [referal_id]);

    const handleWithdraw = () => {
        const isEnoughMinimalTons =
            parseFloat(settings?.withdraw_minimal_ton_value) <
            parseFloat(userInfo?.ton_balance);

        if (!isEnoughMinimalTons) {
            toast.error(
                'You need more tons to withdraw.\nMinimal amount of tons is: ' +
                    settings?.withdraw_minimal_ton_value,
                { duration: 6000 }
            );
        } else {
            setOpenRWModal(true);
        }
    };

    useEffect(() => {
        const remindedFriendsFromStorage =
            JSON.parse(localStorage.getItem('remindedFriends')) || [];
        setLocalRemindedFriends(remindedFriendsFromStorage);
    }, []);

    const handleRemindClick = (chatId) => {
        const now = Date.now();
        const expiredAt = now + 60 * 60 * 1000; // 1 hour from now
        const remindedFriend = { chatId, remindedAt: now, expiredAt };

        // Save to local storage
        const storedRemindedFriends =
            JSON.parse(localStorage.getItem('remindedFriends')) || [];
        storedRemindedFriends.push(remindedFriend);
        localStorage.setItem(
            'remindedFriends',
            JSON.stringify(storedRemindedFriends)
        );

        // Dispatch to Redux store
        sendStorageRemind(chatId, initData);
        dispatch(friendAction.setRemindedFriends(remindedFriend));
        setLocalRemindedFriends((prev) => [...prev, chatId]);
    };

    const canRemindFriend = (chatId) => {
        const now = Date.now();
        const isFriend = friends.some((item) => item.chat_id === chatId);
        const isRemindedFriend = remindedFriends.find(
            (item) => item.chatId === chatId
        );
        const isLocalRemindedFriend = localRemindedFriends.includes(chatId);

        if (isFriend && !isLocalRemindedFriend) {
            if (!isRemindedFriend || isRemindedFriend.expiredAt < now) {
                return true;
            }
        }
        return false;
    };

    useEffect(() => {
        if (!hasScrolled && friendsContainerRef.current) {
            friendsContainerRef.current.scrollTop = 0;
            setHasScrolled(true);
        }
    }, [hasScrolled]);

    useEffect(() => {
        const isInsufficientBalance = userInfo?.ton_balance <= 0;
        const hasPendingOrSentWithdraw = withdraw?.some(
            (item) => item.status === 'Pending' || item.status === 'Sent'
        );
        setWithdrawDisabled(isInsufficientBalance || hasPendingOrSentWithdraw);
    }, [withdraw, userInfo?.ton_balance]);

    return (
        <Wrapper>
            <div className="friends-container">
                <Switch>
                    <Route exact path={path}>
                        <div className="referral-withdraw-card">
                            <div className="rwc-withdraw-info">
                                <div className="rwc-wi-invite-info">
                                    <span className="rwc-wi-icon-container">
                                        <img
                                            className="rwc-wi-icon"
                                            src={tonIcon}
                                            alt="ton icon"
                                        ></img>
                                    </span>
                                    <div className="rwc-wi-invite">
                                        <span className="rwc-wi-invite-header">
                                            Invite Friends
                                        </span>
                                        <span className="rwc-wi-invite-text">
                                            Earn 5% cashback every time your
                                            friend buys boosts for TON. Watch
                                            your rewards grow as they continue
                                            to upgrade!
                                        </span>
                                    </div>
                                </div>
                                <div className="rwc-wi-ton-info">
                                    <div className="rwc-wi-ton-container">
                                        <span className="rwc-wi-ton-text">
                                            Acquired:
                                        </span>
                                        <div className="rwc-wi-ton-block">
                                            <img
                                                src={tonIcon}
                                                alt="ton icon"
                                                className="rwc-wi-ton-block-icon"
                                            />
                                            <span className="rwc-wi-ton-block-tons">
                                                {parseFloat(
                                                    userInfo?.ton_balance
                                                ).toFixed(4) || 0}
                                            </span>
                                            <span className="rwc-wi-ton-block-name">
                                                TON
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="rwc-wi-history-withdraw">
                                    <Button
                                        className="button btn-history"
                                        onClick={handleOpenHistory}
                                    >
                                        <img
                                            className="btn-wh-history-icon"
                                            src={historyIcon}
                                            alt="history icon"
                                        ></img>
                                        History
                                    </Button>
                                    <Button
                                        className="button btn-withdraw"
                                        onClick={handleWithdraw}
                                        disabled={withdrawDisabled}
                                    >
                                        Withdraw
                                    </Button>
                                </div>
                            </div>
                            <div className="rwc-friends-income-container">
                                <div className="rwc-fic-block">
                                    <div className="rwc-fic-total-friends">
                                        <div className="rwc-fic-text-container">
                                            <span className="rwc-fic-header">
                                                My Friends
                                            </span>
                                            <span className="rwc-fic-count">
                                                ({totalFriends})
                                            </span>
                                        </div>
                                    </div>
                                    <div className="rwc-fic-total-rig">
                                        <span className="rwc-fic-rig-tooltip">
                                            <img src={infoIcon} alt="info icon"></img>
                                            <span className="rig-tooltip-text">
                                                Every time your friend claims
                                                coins you get 20% cashback. And
                                                5% every time their referrals
                                                claim it.
                                            </span>
                                        </span>
                                        <div className="rwc-fic-total-income">
                                            <img
                                                src={StarIcon}
                                                alt="rigel icon"
                                            ></img>
                                            <div className="rwc-fic-text-container">
                                                <span className="rwc-fic-rigel">
                                                    {parseFloat(
                                                        referralTokens
                                                    ).toFixed(4)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {friends.length > 0 ? (
                            <div className="friend-list">
                                <div
                                    className="scroll-wrapper"
                                    ref={friendsContainerRef}
                                >
                                    {friends.map((friend, index) => (
                                        <div
                                            className="friend-info"
                                            key={friend.chat_id}
                                            ref={
                                                index === friends.length - 1
                                                    ? (node) => {
                                                          lastFriendElementRef.current =
                                                              node;
                                                          if (
                                                              linkNext !== null
                                                          ) {
                                                              setupObserver();
                                                          }
                                                      }
                                                    : null
                                            }
                                        >
                                            <div className="friend">
                                                <SuspenseImage
                                                    className="friend-img"
                                                    src={
                                                        friend.photo_url ||
                                                        AnonymousIcon
                                                    }
                                                    alt={`${friend.first_name}'s avatar`}
                                                    width="4.5rem"
                                                    height="4.5rem"
                                                />
                                                <div className="info">
                                                    <span className="name">
                                                        {friend.first_name || (
                                                            <Skeleton />
                                                        )}
                                                    </span>
                                                    <div className="amount">
                                                        <SuspenseImage
                                                            className="amount-img"
                                                            src={StarIcon}
                                                            alt="diamond icon"
                                                            width="2rem"
                                                            height="2rem"
                                                        />
                                                        <span className="coins">
                                                            {parseFloat(
                                                                friend.balance
                                                            ).toFixed(4) || (
                                                                <Skeleton />
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="info-tons">
                                                    <div className="tons">
                                                        <SuspenseImage
                                                            className="tons-img"
                                                            src={tonIcon}
                                                            alt="tons icon"
                                                            width="2rem"
                                                            height="2rem"
                                                        />
                                                        <span className="toncoin">
                                                            +
                                                            {parseFloat(
                                                                friend.tons_earned
                                                            ).toFixed(4) || (
                                                                <Skeleton />
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                {friend.claim &&
                                                    friend.chat_id &&
                                                    friend.claim
                                                        .can_be_storaged &&
                                                    canRemindFriend(
                                                        friend.chat_id
                                                    ) && (
                                                        <div className="remind">
                                                            <Button
                                                                className="friend-remind-btn glowing"
                                                                onClick={() =>
                                                                    handleRemindClick(
                                                                        friend.chat_id
                                                                    )
                                                                }
                                                            >
                                                                Remind
                                                            </Button>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    ))}
                                    {showLoading && linkNext !== null && (
                                        <div className="loading-indicator">
                                            <span className="loader"></span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="no-referrals-container">
                                <div className="no-referrals">
                                    <span className="title">
                                        You have no friends
                                    </span>
                                    <span className="info">
                                        Invite your friends and colleagues in
                                        order to get bonuses and complete quests
                                        together
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className="friends-footer">
                            <div className="invite-card">
                                <Button
                                    className="invite-btn"
                                    onClick={handleInviteClick}
                                >
                                    Invite a friend
                                    {friends.length <= 0 && (
                                        <div className="no-referrals-friend-notification"></div>
                                    )}
                                </Button>
                                <Button
                                    className="copy-btn"
                                    onClick={copyInviteLink}
                                >
                                    <SuspenseImage
                                        src={copyIcon}
                                        alt="copy icon"
                                        width="2.4rem"
                                        height="2.4rem"
                                    />
                                </Button>
                            </div>
                        </div>
                    </Route>
                    <Route
                        path={`${path}/history`}
                        component={WithdrawHistory}
                    />
                </Switch>
            </div>
            <Popup
                title="Withdraw"
                className="payment-popup"
                open={openRWModal}
                onClose={() => {
                    setOpenRWModal(false);
                }}
            >
                <ReferralWithdrawModal
                    userInfo={userInfo}
                    onClose={() => {
                        setOpenRWModal(false);
                    }}
                />
            </Popup>
        </Wrapper>
    );
};

export default Friends;
