import React from 'react';
import useTimeUntil from '../../hooks/useTimeLeft';
import SuspenseImage from '../SuspenseImage/SuspenseImage';
import Skeleton from 'react-loading-skeleton';
import starGradientIcon from '../../assets/components/StarIcon/RigelLogo.svg';
import starIcon from '../../assets/components/StarIcon/RigelWhite.svg';
import './CoinInfo.scss';

const CoinInfo = ({
    children,
    balance,
    boostRate,
    boostFinishedAt,
    boostRateMiningStatus,
    coinsMined,
    boostCoinsMined,
}) => {
    const timeLeft = useTimeUntil(boostFinishedAt);

    return (
        <div className="coin-info">
            <div className="coin-info-container">
                <span className="version">Alpha version</span>
                <span className="label">Mining:</span>
                <div className="counter">
                    <SuspenseImage
                        className="counter-gem"
                        alt="eni-dimond"
                        src={starGradientIcon}
                        width="4rem"
                        height="4rem"
                        cricle="true"
                    ></SuspenseImage>
                    <span className="counter-value">
                        {(
                            parseFloat(coinsMined) +
                            parseFloat(boostCoinsMined ? boostCoinsMined : 0)
                        ).toFixed(4) || (
                            <Skeleton
                                width="20rem"
                                containerClassName="counter-value"
                            />
                        )}
                    </span>
                </div>
                {boostRateMiningStatus === 'Active' && (
                    <div className="coin-info-ii-wrapper">
                        <span className="rate">x{boostRate}</span>
                        <span className="time">
                            {timeLeft === '0' ? 'finishing...' : timeLeft}
                        </span>
                    </div>
                )}
                <div className="balance-container">
                    <span className="balance">Total Balance:</span>
                    <span className="storage-info">
                        <SuspenseImage
                            className="counter-gem"
                            alt="eni-dimond"
                            src={starIcon}
                            width="2rem"
                            height="2rem"
                            cricle="true"
                        ></SuspenseImage>
                        <span className="balance-coins">
                            {Number(balance ? balance : 0).toFixed(4) || (
                                <Skeleton width="10rem" />
                            )}
                        </span>
                    </span>
                </div>
            </div>
            {children}
        </div>
    );
};

export default CoinInfo;
