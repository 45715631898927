import { useState, useEffect } from 'react';

const useProgressPercent = (createdAtTime, finishedAtTime) => {
    const [percentComplete, setPercentComplete] = useState(0);

    const calculateProgress = () => {
        const createdAt = new Date(createdAtTime).getTime();
        const finishedAt = new Date(finishedAtTime).getTime();
        const now = new Date().getTime();

        if (createdAt > finishedAt) {
            setPercentComplete(100);
            return;
        }

        const totalDuration = finishedAt - createdAt;
        const elapsedTime = now - createdAt;
        const percent = (elapsedTime / totalDuration) * 100;

        setPercentComplete(Math.min(Math.max(percent, 0), 100));
    };

    useEffect(() => {
        let animationFrameId;
        let lastTime = 0;
        let intervalDuration = 10000;

        calculateProgress();

        const loop = (timestamp) => {
            const delta = timestamp - lastTime;
            if (delta >= intervalDuration) {
                calculateProgress();
                lastTime = timestamp;
            }

            animationFrameId = requestAnimationFrame(loop);
        };

        animationFrameId = requestAnimationFrame(loop);

        return () => cancelAnimationFrame(animationFrameId);
    }, [createdAtTime, finishedAtTime]);

    return percentComplete;
};

export default useProgressPercent;
