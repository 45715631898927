import { useState, useEffect } from 'react';

const useTimeUntil = (validUntil) => {
    const [timeLeft, setTimeLeft] = useState('');

    // Function to calculate the remaining time
    const calculateTimeLeft = () => {
        const endTime = new Date(validUntil).getTime(); // Use UTC time
        const now = new Date().getTime();
        const timeDiff = endTime - now;

        if (timeDiff <= 0) {
            setTimeLeft('0');
            return;
        }

        const totalSecondsLeft = Math.floor(timeDiff / 1000);
        const totalMinutesLeft = Math.floor(timeDiff / (1000 * 60));
        const daysLeft = Math.floor(totalMinutesLeft / (24 * 60));
        const remainderAfterDays = totalMinutesLeft % (24 * 60);
        const hoursLeft = Math.floor(remainderAfterDays / 60);
        const minutesRemaining = remainderAfterDays % 60;
        const secondsRemaining = totalSecondsLeft % 60;

        if (daysLeft > 100) {
            setTimeLeft('unlimited');
        } else if (
            daysLeft === 0 &&
            hoursLeft === 0 &&
            minutesRemaining === 0
        ) {
            setTimeLeft(`${secondsRemaining}s`);
        } else if (daysLeft === 0 && hoursLeft === 0) {
            setTimeLeft(`${minutesRemaining}m, ${secondsRemaining}s`);
        } else if (daysLeft === 0) {
            setTimeLeft(`${hoursLeft}h, ${minutesRemaining}m`);
        } else {
            setTimeLeft(`${daysLeft}d, ${hoursLeft}h, ${minutesRemaining}m`);
        }
    };

    useEffect(() => {
        let animationFrameId;
        let lastTime = 0;
        const updateInterval = timeLeft.includes('s') ? 5000 : 60000;

        calculateTimeLeft();

        const loop = (timestamp) => {
            const delta = timestamp - lastTime;

            if (delta >= updateInterval) {
                calculateTimeLeft();
                lastTime = timestamp;
            }

            animationFrameId = requestAnimationFrame(loop);
        };

        animationFrameId = requestAnimationFrame(loop);

        return () => cancelAnimationFrame(animationFrameId);
    }, [validUntil, timeLeft]);

    return timeLeft;
};

export default useTimeUntil;
