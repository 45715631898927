import {
    useTonConnectModal,
    useIsConnectionRestored,
    useTonConnectUI,
    useTonWallet,
} from '@tonconnect/ui-react';
import { beginCell, Cell } from '@ton/ton';
import { sendCompleteTask } from '../../tools/requests';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import Button from '../Button/Button';
import { missionAction } from '../../store/reducers';
import './TonSendTransaction.scss';

const address = process.env.REACT_APP_TON_ADDRESS;

const TonSendTransaction = (props) => {
    const dispatch = useDispatch();
    const wallet = useTonWallet();
    const isConnectionRestored = useIsConnectionRestored();
    const [tonConnectUI] = useTonConnectUI();
    const { close } = useTonConnectModal();

    const userInfo = useSelector((state) => state.telegram.userInfo);
    const initData = useSelector((state) => state.telegram.initData);

    const [isInProgress, setIsInProgress] = useState(false);

    useEffect(() => {
        setBtnContent(getTxButtonContent());
    }, [isConnectionRestored, isInProgress, wallet]);

    const getTxButtonContent = () => {
        if (!isConnectionRestored) return 'Loading...';
        if (isInProgress) return 'In progress...';
        return wallet ? `Pay ${props.tonAmount} TON` : `Connect TON`;
    };

    const [btnContent, setBtnContent] = useState(getTxButtonContent());

    const onSendTx = async () => {
        try {
            if (!wallet) {
                tonConnectUI.openModal();
                return;
            }
            setIsInProgress(true);
            const taskCategory = Object.keys(props.userTasks).find((category) =>
                props.userTasks[category].some(
                    (task) => task.id === props.currentTaskId
                )
            );
            const updatedTasks = props.userTasks[taskCategory].map((task) =>
                task.id === props.currentTaskId
                    ? { ...task, status: 'Started' }
                    : task
            );
            dispatch(
                missionAction.setTasks({
                    ...props.userTasks,
                    [taskCategory]: updatedTasks,
                })
            );

            const message = JSON.stringify({
                userId: props.userId,
                itemType: props.itemType,
                itemId: props.itemId,
            });

            const base64message = btoa(message);

            const body = beginCell()
                .storeUint(0, 32)
                .storeStringTail(base64message)
                .endCell();

            const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 360,
                messages: [
                    {
                        address: address,
                        amount: Math.floor(
                            props.tonAmount.toString() * Math.pow(10, 9)
                        ),
                        payload: body.toBoc().toString('base64'),
                    },
                ],
            };

            const TonResult = await tonConnectUI.sendTransaction(transaction);
            if (TonResult) {
                const hashHex = Cell.fromBase64(TonResult.boc)
                    .hash()
                    .toString('hex');
                const txHashBytes = Buffer.from(hashHex, 'hex');
                const hash = Buffer.from(txHashBytes, 'hex').toString('base64');
                await sendCompleteTask(
                    initData,
                    userInfo,
                    dispatch,
                    props.currentTaskId,
                    hash
                ).then(() => {
                    toast.loading(
                        'Transaction is processed, it may take up to 2 minutes.'
                    );
                    props.onClose();
                    close();
                });
            }
        } catch (error) {
            const taskCategory = Object.keys(props.userTasks).find((category) =>
                props.userTasks[category].some(
                    (task) => task.id === props.currentTaskId
                )
            );
            const updatedTasks = props.userTasks[taskCategory].map((task) =>
                task.id === props.currentTaskId
                    ? { ...task, status: 'Available' }
                    : task
            );
            dispatch(
                missionAction.setTasks({
                    ...props.userTasks,
                    [taskCategory]: updatedTasks,
                })
            );
            toast.error('Transaction cancelled :(');
            close();
            props.onClose();
        } finally {
            setIsInProgress(false);
        }
    };

    return (
        <div className="ton-btn-container">
            <Button
                className={props.className}
                disabled={!isConnectionRestored || isInProgress}
                onClick={onSendTx}
            >
                {btnContent}
            </Button>
            {props.children}
        </div>
    );
};

export default TonSendTransaction;
