import React, { forwardRef } from 'react';
import withdrawIcon from '../../assets/components/WithdrawHistory/WithdrawIcon.svg';
import './WithdrawHistoryTransaction.scss';

const WithdrawHistoryTransaction = forwardRef(
    (
        { tonAmount, tonAddress, status, refused_reason, link, created_at },
        ref
    ) => {
        const dateObj = new Date(created_at);
        const date = dateObj.toISOString().split('T')[0].replace(/-/g, '.');
        const time = dateObj
            .toISOString()
            .split('T')[1]
            .split('Z')[0]
            .slice(0, 5);
        const reason = refused_reason ? refused_reason : '';

        const transactionSuccessColor = [
            { status: 'Approved', color: '#1AB08C' },
            { status: 'Refused', color: '#C84058' },
            { status: 'Pending', color: '#B2B2B2' },
        ];

        const statusColor = transactionSuccessColor.find(
            (item) => item.status === status
        )?.color;

        return (
            <div className="whc-cl-transaction-container" ref={ref}>
                <div className="whc-cl-tc-icon-container">
                    <span className="whc-tc-img-container">
                        <img
                            className="whc-tc-img"
                            src={withdrawIcon}
                            alt="withdraw icon"
                        />
                    </span>
                </div>
                <div className="whc-cl-tc-info-container">
                    <span className="whc-tc-ton">
                        <span className="whc-tc-amount">
                            {parseFloat(tonAmount).toFixed(4)}
                        </span>
                        <span className="whc-tc-name">TON</span>
                    </span>
                    <span className="whc-tc-address">{tonAddress}</span>
                    <span
                        className="whc-tc-status"
                        style={{ color: statusColor }}
                    >
                        {status}
                        {reason ? `: ${reason}` : ''}
                    </span>
                    {status !== 'Refused' && (
                        <a
                            className="whc-tc-transaction-link"
                            href={link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            View Transaction
                        </a>
                    )}
                </div>
                <div className="whc-cl-tc-datetime-container">
                    <span className="whc-tc-time">{time}</span>
                    <span className="whc-tc-date">{date}</span>
                </div>
            </div>
        );
    }
);

export default WithdrawHistoryTransaction;
