import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    appPrepared: false,
    error: null,
    settings: {},
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAppPrepared: (state, action) => {
            state.appPrepared = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setSettings: (state, action) => {
            state.settings = action.payload;
        },
    },
});

export const { setAppPrepared, setError, setSettings } = appSlice.actions;

export default appSlice.reducer;
