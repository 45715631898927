import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    appAction,
    telegramAction,
    friendAction,
    boostAction,
} from './store/reducers';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import {
    sendUserInfo,
    getUserBoosts,
    getUserAchievements,
    getUserTasks,
    fetchReferralData,
    fetchGames,
    getProducts,
    getDailyRewards,
    getTonInfo,
    fetchWithdrawData,
    fetchSettings,
} from './tools/requests';
import RouterSwitch from './RouterSwitch';
import Roadmap from './pages/Roadmap/Roadmap';
import startNotificationLoop from './hooks/useNotification';
import toast from 'react-hot-toast';
import Background from './layouts/Background/Background';
import Tutorial from './pages/Tutorial/Tutorial';
import Error from './pages/Error/Error';
import Popup from './components/Popup/Popup';
import AutoCollectoTimeoutModal from './modals/AutoCollectoTimeoutModal/AutoCollectoTimeoutModal';
import Modal from './components/Modal/Modal';
import DailyClaimModal from './modals/DailyClaimModal/DailyClaimModal';
import startTonRateLoop from './hooks/useTonRate';
import './style/main.scss';

function App() {
    const dispatch = useDispatch();

    const tg = window.Telegram.WebApp;
    const initData = tg.initData;

    const isAppPrepared = useSelector((state) => state.app.appPrepared);
    const userInfo = useSelector((state) => state.telegram.userInfo);
    const dailyStreak = useSelector((state) => state.daily.dailyStreak);
    const errorStatus = useSelector((state) => state.app.error);
    const openResumeCollectorModal = useSelector(
        (state) => state.boost.openResumeCollectorModal
    );

    const [error, setError] = useState(null);
    const [showRoadmap, setShowRoadmap] = useState(true);
    const [showTutorial, setShowTutorial] = useState(false);
    const [openDailyClaimModal, setOpenDailyClaimModal] = useState(false);

    useEffect(() => {
        dispatch(telegramAction.setInitData(initData));

        const urlParams = new URLSearchParams(window.location.search);
        const tgWebAppStartParam = urlParams.get('tgWebAppStartParam');

        const referralCode = tgWebAppStartParam;

        if (!initData) {
            setError(
                <>
                    <h3>It seems like you're trying to access</h3>
                    <h3>an app not via Telegram</h3>
                    <h3>The app will not go further!</h3>
                </>
            );
            return;
        }

        sendUserInfo(initData, referralCode, dispatch)
            .then(() => {
                const promises = [
                    fetchSettings(initData, dispatch),
                    getUserBoosts(initData, dispatch),
                    getUserTasks(initData, dispatch),
                    getUserAchievements(initData, dispatch),
                    getProducts(initData, dispatch),
                    getDailyRewards(initData, dispatch),
                    fetchGames(initData, dispatch),
                    getTonInfo(initData, dispatch),
                    fetchReferralData(1, initData, dispatch, false, []),
                    fetchWithdrawData(1, initData, dispatch, false, []),
                ];
                return Promise.all(promises);
            })
            .then(() => {
                // Load reminded friends from local storage
                const storedRemindedFriends =
                    JSON.parse(localStorage.getItem('remindedFriends')) || [];
                const now = Date.now();
                const validRemindedFriends = storedRemindedFriends.filter(
                    (friend) => friend.expiredAt > now
                );

                validRemindedFriends.forEach((friend) => {
                    dispatch(friendAction.setRemindedFriends(friend));
                });

                // Update local storage with valid reminded friends
                localStorage.setItem(
                    'remindedFriends',
                    JSON.stringify(validRemindedFriends)
                );

                startNotificationLoop(initData, dispatch);
                startTonRateLoop(initData, dispatch);

                dispatch(appAction.setAppPrepared(true));
            })
            .catch((err) => {
                console.error('Some problem during initialization', err);
            });
    }, [initData, dispatch]);

    const handleInvoiceClosed = useCallback((object) => {
        if (object.status === 'paid') {
            toast.loading(
                'Transaction is processed, it may take up to 2 minutes.'
            );
        } else if (object.status === 'failed') {
            toast.error('Telegram stars transaction failed');
        }
    }, []);

    useEffect(() => {
        tg.onEvent('invoiceClosed', handleInvoiceClosed);
        return () => {
            tg.offEvent('invoiceClosed', handleInvoiceClosed);
        };
    }, [tg, handleInvoiceClosed]);

    useEffect(() => {
        const roadmapTimer = setTimeout(() => {
            setShowRoadmap(false);

            const tutorialShown = localStorage.getItem('tutorialShown');
            if (!tutorialShown) {
                setShowTutorial(true);
            }
        }, 3000); // Show roadmap for 3 seconds

        return () => clearTimeout(roadmapTimer);
    }, []);

    useEffect(() => {
        if (!showTutorial && !showRoadmap && isAppPrepared && !error) {
            if (!userInfo?.daily_reward) return;
            const timer = setTimeout(() => {
                setOpenDailyClaimModal(true);
            }, 2500);

            return () => clearTimeout(timer);
        } else return;
    }, [
        userInfo?.daily_reward,
        error,
        showTutorial,
        showRoadmap,
        isAppPrepared,
    ]);

    const handleCloseTutorial = () => {
        localStorage.setItem('tutorialShown', 'true');
        setShowTutorial(false);
    };

    if (error) {
        return <Error description={error} code={401} />;
    }

    if (errorStatus) {
        return (
            <Error
                description={errorStatus.description}
                code={errorStatus.code}
            />
        );
    }

    if (!isAppPrepared && !error) {
        return <Roadmap />;
    }

    if (isAppPrepared && !userInfo) {
        return <Error />;
    }

    if (showRoadmap) {
        return <Roadmap />;
    }

    if (showTutorial) {
        return <Tutorial onClose={handleCloseTutorial} />;
    }

    return (
        <BrowserRouter>
            <div className="global-container">
                <Background />
                <RouterSwitch />
            </div>
            <Popup
                title=""
                className="payment-popup"
                open={openResumeCollectorModal}
                onClose={() =>
                    dispatch(boostAction.setOpenResumeCollectorModal(false))
                }
                closeBtnDisabled={true}
            >
                <AutoCollectoTimeoutModal
                    onClose={() =>
                        dispatch(boostAction.setOpenResumeCollectorModal(false))
                    }
                />
            </Popup>
            {userInfo?.daily_reward && (
                <Modal
                    className="glowing-modal"
                    title="Your daily rewards"
                    open={openDailyClaimModal}
                    onClose={() => setOpenDailyClaimModal(false)}
                    closeBtnDisabled={true}
                >
                    <DailyClaimModal
                        onClose={() => setOpenDailyClaimModal(false)}
                        title="Come back tomorrow for new tickets"
                        dailyRewards={userInfo?.daily_reward}
                        dailyStreak={dailyStreak}
                    />
                </Modal>
            )}
            <Toaster
                position="top-center"
                reverseOrder={false}
                toastOptions={{
                    style: {
                        fontFamily: 'Urbanist, sans-serif',
                        fontWeight: '500',
                        fontStyle: 'normal',
                        fontSize: '1.8rem',
                    },
                }}
            />
        </BrowserRouter>
    );
}

export default App;
