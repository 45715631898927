import React from 'react';
import Button from '../Button/Button';
import autocllectoIcon from '../../assets/components/AutoCollectorIcon/AutoCollectorIconTwo.svg';
import incomeIncreaseIcon from '../../assets/components/IncomeIncreaseIcon/IncomeIncreaseIcon.svg';
import SuspenseImage from '../SuspenseImage/SuspenseImage';
import Skeleton from 'react-loading-skeleton';
import './Upgrades.scss';

const Upgrades = ({
    setOpenCollectorModal,
    setOpenIncomeModal,
    isAutoCollectorActive,
    isIncomeIncreaseActive,
}) => {
    return (
        <div className="upgrades-btn-container">
            <Button
                className={`upgrades-btn ${
                    isAutoCollectorActive ? 'active' : ''
                }`}
                onClick={
                    isAutoCollectorActive
                        ? () => {}
                        : () => setOpenCollectorModal(true)
                }
            >
                <div className="upgrades-comp">
                    <div className="upgrades-info-container">
                        <SuspenseImage
                            src={autocllectoIcon}
                            className="upgrades-img"
                            height="4rem"
                            width="4rem"
                        ></SuspenseImage>

                        <div className="upgrades-text-block">
                            <span className="upgrades-first-part-text">
                                Auto-
                            </span>
                            <span className="upgrades-second-part-text">
                                Collector
                            </span>
                            <span
                                className={`upgrades-sub-text ${
                                    isAutoCollectorActive
                                        ? 'upgrades-gradient-text'
                                        : ''
                                }`}
                            >
                                {(isAutoCollectorActive ? 'Active' : 'Buy') || (
                                    <Skeleton />
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            </Button>
            <Button
                className={`upgrades-btn ${
                    isIncomeIncreaseActive ? 'active' : ''
                }`}
                onClick={
                    isIncomeIncreaseActive
                        ? () => {}
                        : () => setOpenIncomeModal(true)
                }
            >
                <div className="upgrades-comp">
                    <div className="upgrades-info-container">
                        <SuspenseImage
                            src={incomeIncreaseIcon}
                            className="upgrades-img"
                            height="4rem"
                            width="4rem"
                        ></SuspenseImage>

                        <div className="upgrades-text-block">
                            <span className="upgrades-first-part-text">
                                Income
                            </span>
                            <span className="upgrades-second-part-text">
                                Increase
                            </span>
                            <span
                                className={`upgrades-sub-text ${
                                    isIncomeIncreaseActive
                                        ? 'upgrades-gradient-text'
                                        : ''
                                }`}
                            >
                                {(isIncomeIncreaseActive
                                    ? 'Active'
                                    : 'Buy') || <Skeleton />}
                            </span>
                        </div>
                    </div>
                </div>
            </Button>
            <Button className="upgrades-btn transparent-btn" onClick={() => {}}>
                Storage Increase
            </Button>
        </div>
    );
};
export default Upgrades;
