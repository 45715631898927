import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userInfo: {},
    initData: null,
};

export const telegramSlice = createSlice({
    name: 'telegram',
    initialState,
    reducers: {
        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
        setInitData: (state, action) => {
            state.initData = action.payload;
        },
    },
});

export const { setUserInfo, setInitData } = telegramSlice.actions;

export default telegramSlice.reducer;
