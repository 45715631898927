import { useEffect, useState } from 'react';
import { toFixed } from '../tools/common';

const useMinedTokens = (createdAt, finishedAt, tokensPerHour, claimHours) => {
    const [minedTokens, setMinedTokens] = useState(0);

    const calculateTokens = () => {
        const createdAtDate = new Date(createdAt).getTime();
        const finishedAtDate = new Date(finishedAt).getTime();
        const now = Date.now();

        if (isNaN(createdAtDate) || isNaN(finishedAtDate)) {
            console.error('Invalid dates for createdAt or finishedAt');
            return;
        }

        const tokensPerSecond = parseFloat(tokensPerHour) / 3600;
        if (isNaN(tokensPerSecond)) {
            console.error('Invalid tokensPerHour value', tokensPerHour);
            return;
        }

        // Calculate elapsed time
        const maxClaimSeconds = claimHours * 3600;
        let elapsedTime = Math.max((now - createdAtDate) / 1000, 0);
        elapsedTime = Math.min(
            elapsedTime,
            maxClaimSeconds,
            (finishedAtDate - createdAtDate) / 1000
        );

        const currentMinedTokens = elapsedTime * tokensPerSecond;

        setMinedTokens(toFixed(currentMinedTokens, 6));
    };

    useEffect(() => {
        let animationFrameId;
        let lastTime = 0;

        calculateTokens();

        const loop = (timestamp) => {
            const delta = timestamp - lastTime;
            if (delta >= 2000) {
                calculateTokens();
                lastTime = timestamp;
            }

            animationFrameId = requestAnimationFrame(loop);
        };
        animationFrameId = requestAnimationFrame(loop);

        return () => cancelAnimationFrame(animationFrameId);
    }, [createdAt, finishedAt, tokensPerHour, claimHours]);

    return minedTokens;
};

export default useMinedTokens;
