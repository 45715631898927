import React, { useEffect, useState } from 'react';
import { sendUserClaim } from '../../tools/requests';
import { convertDollarToTon } from '../../tools/common';
import Button from '../../components/Button/Button';
import rigelWhiteIcon from '../../assets/components/StarIcon/RigelWhite.svg';
import rigelTripleIcon from '../../assets/components/StarIcon/RigelTriple.svg';
import bestDealIcon from '../../assets/components/BestDealIcon/CoinX2Icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import './X2ClaimModal.scss';

const X2ClaimModal = ({
    title,
    onClose,
    setOpenClaimPaymentModal,
    setTonAmountForPayment,
    setStarsAmountForPayment,
    setDollarAmountForPayment,
    coinsMined,
    boostCoinsMined,
}) => {
    const dispatch = useDispatch();

    const products = useSelector((state) => state.boost.products);
    const tonRate = useSelector((state) => state.payment.tonAmount);

    const x2ClaimProducts = products.RateClaim.levels;
    const x2ClaimRate = Number(x2ClaimProducts[0].options.rate);
    const totalCoins = (parseFloat(coinsMined) + parseFloat(boostCoinsMined ? boostCoinsMined : 0)) ;
    const userData = window.Telegram.WebApp.initData;

    const [dollarAmount, setDollarAmount] = useState(0);

    useEffect(() => {
        const product = x2ClaimProducts[0];
        const dollarPrice = product.price.USD;
        const starsPrice = product.price.XTR;
        const tonPrice = convertDollarToTon(dollarPrice, tonRate);
        setDollarAmount(dollarPrice);
        setTonAmountForPayment(tonPrice);
        setStarsAmountForPayment(starsPrice);
        setDollarAmountForPayment(dollarPrice);
    }, [products, tonRate]);

    const handleCommonClaim = () => {
        sendUserClaim(userData, dispatch);
        onClose();
    };

    const handleDoubleClaim = () => {
        setOpenClaimPaymentModal();
        onClose();
    };

    return (
        <div className="claim-modal-container">
            <div className="double-claim-title">
                <span className="title">{title}</span>
            </div>
            <div className="two-claims">
                <div className="double-claim-container">
                    <div className="reward-container">
                        <img src={rigelWhiteIcon} alt="" className="img" />
                    </div>

                    <span className="coin-gathered">
                        <img src={rigelWhiteIcon} alt="" className="coin-img" />
                        <span className="coins">
                            {totalCoins.toFixed(4)}
                        </span>
                    </span>
                    <span className="ton-img">Free</span>
                    <Button className="select" onClick={handleCommonClaim}>
                        Select
                    </Button>
                </div>
                <div className="double-claim-container">
                    <div className="reward-container">
                        <img src={rigelTripleIcon} alt="" className="img" />
                    </div>
                    <span className="coin-gathered">
                        <img src={rigelWhiteIcon} alt="" className="coin-img" />
                        <span className="coins">
                            {(totalCoins * x2ClaimRate).toFixed(4)}
                        </span>
                    </span>
                    <span className="ton-img">$ {dollarAmount}</span>
                    <Button
                        className="select gradient"
                        onClick={handleDoubleClaim}
                    >
                        Select
                    </Button>
                    <img
                        className="best-deal-icon"
                        src={bestDealIcon}
                        alt="best deal icon"
                    />
                </div>
            </div>
        </div>
    );
};

export default X2ClaimModal;
