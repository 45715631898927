import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tasks: [],
    achieves: [],
};

export const missionSlice = createSlice({
    name: 'mission',
    initialState,
    reducers: {
        setTasks: (state, action) => {
            state.tasks = action.payload;
        },
        setAchieves: (state, action) => {
            state.achieves = action.payload;
        },
    },
});

export const { setTasks, setAchieves } = missionSlice.actions;

export default missionSlice.reducer;
