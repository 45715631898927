import './polyfills';
import ReactDOM from 'react-dom/client';

import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { Provider } from 'react-redux';
import ErrorBoundary from './tools/errorBoundary';
import App from './App';
import { store } from './store/store';
import { SkeletonTheme } from 'react-loading-skeleton';
import Error from './pages/Error/Error';
import initTgSettings from './tools/initTgSettings';
//import * as Sentry from '@sentry/react';
//import reportWebVitals from './reportWebVitals';
//import { initGA } from './analytics';
//import { initSentry } from './sentry';
//import initNoCache from './tools/noCache';

initTgSettings();
//initNoCache();
//initGA();
//initSentry();

function Fallback({ error }) {
    return (
        <Error
            description="Please reload your app"
            code="Something went wrong"
            style={{ fontSize: '3rem' }}
        />
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ErrorBoundary FallbackComponent={Fallback}>
        <Provider store={store}>
            <TonConnectUIProvider
                manifestUrl={`${process.env.REACT_APP_LINK}/tonconnect-manifest.json`}
            >
                <SkeletonTheme baseColor="#ebebeb00" highlightColor="#f5f5f5">
                    <App />
                </SkeletonTheme>
            </TonConnectUIProvider>
        </Provider>
    </ErrorBoundary>
);