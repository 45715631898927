import { configureStore } from '@reduxjs/toolkit';
import reducers from './reducers/index';
//import { api } from '../services/api';

//import * as Sentry from '@sentry/react';
/*const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
});*/

export const store = configureStore(
    {
        reducer: {
            app: reducers.appReducer,
            boost: reducers.boostReducer,
            daily: reducers.dailyReducer,
            friend: reducers.friendReducer,
            game: reducers.gameReducer,
            mission: reducers.missionReducer,
            payment: reducers.paymentReducer,
            telegram: reducers.telegramReducer,
            withdraw: reducers.withdrawReducer,
            //[api.reducerPath]: api.reducer,
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
                    ignoredPaths: ['common.socket'],
                },
            })
            //.concat(api.middleware),
        /*enhancers: (getDefaultEnhancers) => {
            return getDefaultEnhancers().concat(sentryReduxEnhancer);
        },*/
    },
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
