import React, { useCallback, useEffect, useRef, useState } from 'react';
import Wrapper from '../../layouts/Wrapper/Wrapper';
import WithdrawHistoryInfo from '../../components/WidthrawHistoryInfo/WithdrawHistoryInfo';
import WithdrawHistoryTransaction from '../../components/WithdrawHistoryTransaction/WithdrawHistoryTransaction';
import './WithdrawHistory.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWithdrawData } from '../../tools/requests';

const WithdrawHistory = () => {
    const dispatch = useDispatch();

    const initData = window.Telegram.WebApp.initData;
    const delay = 2000;

    const currentPage = useSelector((state) => state.withdraw.currentWithdrawsPage);
    const withdraws = useSelector((state) => state.withdraw.withdraws);
    const totalTons = useSelector((state) => state.payment.totalTons);
    const linkNext = useSelector((state) => state.withdraw.linkWithdrawsNext);

    const [hasScrolled, setHasScrolled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showLoading, setShowLoading] = useState(true);

    const observer = useRef();
    const lastWithdrawElementRef = useRef(null);
    const withdrawsContainerRef = useRef(null);

    const setupObserver = useCallback(() => {
        if (observer.current) observer.current.disconnect();

        if (currentPage && linkNext && lastWithdrawElementRef.current) {
            observer.current = new IntersectionObserver(async (entries) => {
                if (entries[0].isIntersecting && !loading) {
                    setLoading(true);
                    setShowLoading(true);

                    await fetchWithdrawData(
                        currentPage + 1,
                        initData,
                        dispatch,
                        true,
                        withdraws
                    );

                    setTimeout(() => {
                        setLoading(false);
                    }, delay);
                }
            });

            observer.current.observe(lastWithdrawElementRef.current);
        }
    }, [dispatch, loading, currentPage, linkNext, initData, withdraws]);

    useEffect(() => {
        if (!hasScrolled && withdrawsContainerRef.current) {
            withdrawsContainerRef.current.scrollTop = 0;
            setHasScrolled(true);
        }
    }, [hasScrolled]);

    return (
        <Wrapper>
            <div className="withdraw-history-container">
                <div className="whc-content">
                    <div className="whc-info-header">
                        <div className="whc-ih-title">History</div>
                        <WithdrawHistoryInfo totalTons={totalTons} />
                    </div>
                    <div className="whc-content-list">
                        <div className="whc-cl-title">Transactions</div>
                        {withdraws.length > 0 ? (
                            <div
                                className="scroll-wrapper"
                                ref={withdrawsContainerRef}
                            >
                                {withdraws.map((item, index) => {
                                    return (
                                        <WithdrawHistoryTransaction
                                            key={index}
                                            tonAmount={item.amount}
                                            tonAddress={item.wallet}
                                            status={item.status}
                                            transTime={item.transTime}
                                            dateTime={item.dateTime}
                                            refused_reason={item.refused_reason}
                                            link={item.link}
                                            created_at={item.created_at}
                                            ref={
                                                index === withdraws.length - 1
                                                    ? (node) => {
                                                          lastWithdrawElementRef.current =
                                                              node;
                                                          if (
                                                              linkNext !== null
                                                          ) {
                                                              setupObserver();
                                                          }
                                                      }
                                                    : null
                                            }
                                        />
                                    );
                                })}
                                {showLoading && linkNext !== null && (
                                    <div className="loading-indicator">
                                        <span className="loader"></span>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="no-withdraws-container">
                                <div className="no-withdraws">
                                    <span className="title">
                                        You have no withdraws yet
                                    </span>
                                    <span className="info">
                                        Invite your friends and colleagues in
                                        order to get tons and ability to
                                        withdraw.
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default WithdrawHistory;
