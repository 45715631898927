import React from 'react';
import Background from '../../layouts/Background/Background';
import errorIcon from '../../assets/components/ErrorPageIcon/ErrorIcon.svg';
import errorGuys from '../../assets/components/ErrorPageIcon/ErrorGuysIcon.png';
import errorPlanet from '../../assets/components/ErrorPageIcon/ErrorPlanet.png';
import './Error.scss';

const Error = ({ description, code, style }) => {
    return (
        <>
            <Background />
            <div className="not-found-container" style={style}>
                <div className="error-space-container"></div>
                <div className="error-info-container">
                    <img
                        className="error-icon"
                        src={errorIcon}
                        alt="error icon"
                    ></img>
                    {code ? (
                        <h1>{code}</h1>
                    ) : (
                        <>
                            <h1>Something</h1>
                            <h1>went wrong</h1>
                        </>
                    )}
                    <h3>{description || 'Please reload your app'}</h3>
                </div>
                <div className="error-guys-container">
                    <img
                        className="error-guys"
                        src={errorGuys}
                        alt="error guys"
                    ></img>
                </div>
                <img
                    className="error-planet"
                    src={errorPlanet}
                    alt="error planet"
                ></img>
            </div>
        </>
    );
};

export default Error;
