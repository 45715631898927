import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import InternetConnection from '../../pages/InternetConnection/InternetConnection';
import useInternetConnection from '../../hooks/useInternetConnection';
import './Wrapper.scss';

const Wrapper = (props) => {
    const tg = window.Telegram.WebApp;

    const history = useHistory();
    const location = useLocation();

    const { isOnline, connectionRestored } = useInternetConnection();

    useEffect(() => {
        const handleBackButtonClick = () => {
            if (location.pathname !== '/') {
                history.goBack();
            } else {
                tg.showPopup(
                    {
                        title: 'Leave the app?',
                        message: 'Are you sure you want to close the app?',
                        buttons: [
                            {
                                id: 'close',
                                type: 'close',
                                text: 'Close',
                            },
                            {
                                id: 'cancel',
                                type: 'cancel',
                                text: 'Cancel',
                            },
                        ],
                    },
                    (buttonId) => {
                        if (buttonId === 'close') {
                            tg.close();
                        }
                    }
                );
            }
        };
        tg.BackButton.onClick(handleBackButtonClick);
        return () => {
            tg.BackButton.offClick(handleBackButtonClick);
        };
    }, [history, location, tg]);

    return (
        <motion.div
            className="wrapper"
            initial={{
                scale: 0.9,
                opacity: 0,
                willChange: 'unset !important',
                zIndex: 'unset !important',
                transitionEnd: {
                    zIndex: 'unset !important',
                    willChange: 'unset !important',
                },
            }}
            animate={{
                scale: 1,
                opacity: 1,
                willChange: 'unset !important',
                zIndex: 'unset !important',
                transitionEnd: {
                    zIndex: 'unset !important',
                    willChange: 'unset !important',
                },
            }}
            exit={{
                scale: 0.9,
                opacity: 0,
                willChange: 'unset !important',
                zIndex: 'unset !important',
                transitionEnd: {
                    zIndex: 'unset !important',
                    willChange: 'unset !important',
                },
            }}
            layout="position"
            style={{
                zIndex: 'unset !important',
                willChange: 'unset !important',
            }}
            transition={{ duration: 0.2 }}
        >
            {!isOnline && <InternetConnection type="lost" />}
            {isOnline && connectionRestored && (
                <InternetConnection type="restored" />
            )}
            {props.children}
        </motion.div>
    );
};

export default Wrapper;
