import React from 'react';
import * as PIXI from 'pixi.js';
import {
    Sprite,
    withPixiApp,
} from '@pixi/react';
import particleImg from '../../assets/animations/ProgressBarIcons/particleImg.png';
//const particleTexture = PIXI.Texture.from(particleImg);

export const Particle = (props) => (
    <Sprite
        {...props}
        image={particleImg}
        anchor={0.5}
        overwriteProps={true}
        ignoreEvents={true}
    />
);

export const Batch = withPixiApp(
    class extends React.PureComponent {
        time = 0;
        bounds = null;
        emissionAccumulator = 0;

        state = { items: [], count: 0, component: null };

        componentDidMount() {
            this.bounds = new PIXI.Rectangle(
                0,
                0,
                this.props.app.screen.width,
                this.props.app.screen.height
            );

            this.props.app.ticker.add(this.tick);
        }

        componentWillUnmount() {
            if (this.props.app && this.props.app.ticker && this.tick) {
                this.props.app.ticker.remove(this.tick);
            }
        }

        tick = (delta) => {
            const deltaSeconds = delta / this.props.app.ticker.FPS;
            this.emissionAccumulator += deltaSeconds;

            const particlesToEmit = Math.floor(
                this.emissionAccumulator * this.props.emissionRate
            );
            this.emissionAccumulator -=
                particlesToEmit / this.props.emissionRate;

            for (let i = 0; i < particlesToEmit; i++) {
                if (this.state.items.length >= this.props.maxParticles) break;

                const spawnX = this.getRandomSpawnX();
                const spawnY = this.props.emitPositionRange.y;

                const direction = Math.random() * Math.PI * 2;
                const speed = 5 + Math.random() * 10.67;
                const lifetime = 1;
                const initialScale = 1.5 + Math.random() * 0.5;

                this.setState((prevState) => ({
                    items: [
                        ...prevState.items,
                        {
                            x: spawnX,
                            y: spawnY,
                            direction,
                            speed,
                            lifetime,
                            age: 0,
                            alpha: 1,
                            rotation: Math.random() * Math.PI * 2,
                            initialScale: initialScale,
                            scale: initialScale,
                        },
                    ],
                }));
            }

            this.setState((prevState) => ({
                items: prevState.items
                    .map((item) => {
                        const newAge = item.age + deltaSeconds;
                        if (newAge > item.lifetime) return null;

                        const velocityX =
                            Math.cos(item.direction) *
                            item.speed *
                            deltaSeconds;
                        const velocityY =
                            Math.sin(item.direction) *
                            item.speed *
                            deltaSeconds;
                        const scaleDecay =
                            1 -
                            newAge / (item.lifetime < 1 ? 1 : item.lifetime);
                        const newScale = item.initialScale * scaleDecay;

                        return {
                            ...item,
                            x: item.x + velocityX,
                            y: item.y + velocityY,
                            alpha: Math.max(1 - newAge / item.lifetime, 0),
                            age: newAge,
                            scale: newScale > 0 ? newScale : 0,
                        };
                    })
                    .filter((item) => item !== null),
            }));

            this.time += deltaSeconds;
        };

        getRandomSpawnX = () => {
            const { minX, maxX } = this.props.emitPositionRange;
            return minX + Math.random() * (maxX - minX);
        };

        render() {
            const Comp = this.props.component;
            return this.state.items.map((props, index) => (
                <Comp
                    key={index}
                    {...props}
                    alpha={props.alpha}
                    scale={props.scale}
                    rotation={props.rotation}
                />
            ));
        }
    }
);