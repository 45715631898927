import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tonAmount: 0,
    isPaymentLoading: false,
    totalTons: 0,
};

export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        setTonAmount: (state, action) => {
            state.tonAmount = action.payload;
        },
        setIsPaymentLoading: (state, action) => {
            state.isPaymentLoading = action.payload;
        },
        setTotalTons: (state, action) => {
            state.totalTons = action.payload;
        },
    },
});

export const { setTonAmount, setIsPaymentLoading, setTotalTons } =
    paymentSlice.actions;

export default paymentSlice.reducer;
