import React from 'react';
import Button from '../../components/Button/Button';
import { purchaseUserBoost } from '../../tools/requests';
import rigelWhiteIcon from '../../assets/components/StarIcon/RigelWhite.svg';
import arrowToIcon from '../../assets/components/BoostIcon/ArrowToIcon.svg';
import { useDispatch } from 'react-redux';
import { boostAction } from '../../store/reducers';
import './BoostModal.scss';

const BoostModal = ({
    id,
    title,
    before,
    beforeIcon,
    after,
    afterIcon,
    underline,
    userInfo,
    cost,
    onClose,
    boostLevel,
    boosts,
}) => {
    const dispatch = useDispatch();
    const initData = window.Telegram.WebApp.initData;

    const isBalanceInsufficient =
        parseFloat(userInfo?.balance) < parseFloat(cost);

    const handleUpgradePurchase =  () => {
        if (Array.isArray(boosts)) {
            let updatedBoosts = boosts.map((boost) => {
                return {
                    ...boost,
                    disabled: true,
                    loading: true,
                };
            });
            dispatch(boostAction.setBoosts(updatedBoosts));
        }
        setTimeout(async () => {
            await purchaseUserBoost(id, initData, userInfo, dispatch);
        }, 2000);
        onClose();
    };

    return (
        <div className="modal-container">
            <div className="upgrade-title">
                <span className="title">{title}</span>
            </div>
            <div className="upgrade-info">
                <span
                    className={
                        isBalanceInsufficient
                            ? 'balance-info insufficient'
                            : 'balance-info'
                    }
                >
                    <span className="label">RIG Available: </span>
                    <span className="balance">
                        <img src={rigelWhiteIcon} alt="rigel white icon"></img>
                        <span>{parseFloat(userInfo.balance).toFixed(3)}</span>
                    </span>
                </span>
                <div className="upgrade-header-container">
                    <img
                        className="upgrade-icon"
                        src={beforeIcon}
                        alt="user avatar"
                    ></img>
                    <div className="upgrade-data">
                        <span className="upgrade-name">
                            Level {boostLevel - 1}
                        </span>
                        <div className="upgrade-info-span">
                            <span className="info">{before}</span>
                        </div>
                    </div>
                </div>
                <div className="upgrade-arrow-continer">
                    <img src={arrowToIcon} alt=""></img>
                </div>
                <div className="upgrade-header-container container-glowing">
                    <img
                        className="upgrade-icon"
                        src={afterIcon}
                        alt="user avatar"
                    ></img>
                    <div className="upgrade-data">
                        <span className="upgrade-name name-glowing">
                            New Level {boostLevel}
                        </span>
                        <div className="upgrade-info-span info-span-glowing">
                            <span className="info">{after}</span>
                        </div>
                    </div>
                </div>
                <span className="upgrade-balance-value">
                    <span className="label">Upgrade cost: </span>
                    <span className="balance">
                        <img src={rigelWhiteIcon} alt="rigel white icon"></img>
                        <span>{parseFloat(cost).toFixed(3)}</span>
                    </span>
                </span>
            </div>
            <Button
                onClick={handleUpgradePurchase}
                className={`modal-btn glowing ${
                    isBalanceInsufficient ? 'disabled' : ''
                }`}
                disabled={isBalanceInsufficient}
            >
                Purchase
            </Button>
        </div>
    );
};

export default BoostModal;
