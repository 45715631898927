import React from 'react';
import SuspenseImage from '../SuspenseImage/SuspenseImage';
import ticketIcon from '../../assets/components/AppsIcon/TicketIcon.svg';
import crownIcon from '../../assets/components/AppsIcon/CrownIcon.svg';
import Button from '../Button/Button';
import './AppsGame.scss';

const AppsGame = React.memo(({
    name,
    cost,
    highscore,
    gamePreviewIcon,
    isNew,
    setOpenStartGameModal,
    setSelectedGame,
}) => {
    const handleOpenApp = () => {
        setSelectedGame();
        setOpenStartGameModal();
    };

    return (
        <Button onClick={() => handleOpenApp()} className="apps-game">
            <div className="apps-game-wrapper">
                <div className="apps-game-img-block">
                    <SuspenseImage
                        src={gamePreviewIcon}
                        className="apps-game-img"
                        alt="soon game icon"
                        width="100%"
                        height="17rem"
                        customWrapperStyle={{
                            width: '100%',
                            height: '100%',
                        }}
                    />
                    {isNew && <div className="apps-new-block">NEW</div>}
                    <span className="apps-img-ticket-info">
                        <SuspenseImage
                            src={ticketIcon}
                            className="apps-ticket-icon"
                            alt="ticket icon"
                            width="1.6rem"
                            height="1.6rem"
                        />
                        <span className="apps-ticket-count">{cost}</span>
                        <span className="apps-ticket-name">Ticket</span>
                    </span>
                </div>
                <div className="apps-game-info">
                    <span className="apps-game-title">{name}</span>
                    <span className="apps-game-price">
                        <SuspenseImage
                            src={crownIcon}
                            className="apps-crown-icon"
                            alt="crown icon"
                            width="1.6rem"
                            height="1.6rem"
                        />
                        <span className="apps-price">{highscore}</span>
                    </span>
                </div>
            </div>
        </Button>
    );
});

export default AppsGame;
