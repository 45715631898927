import React, { useEffect, useState } from 'react';
import Button from '../../components/Button/Button';
import rigelIcon from '../../assets/components/DailyClaim/RigelIcon.svg';
import ticketIcon from '../../assets/components/DailyClaim/TicketIcon.png';
import rigelLine from '../../assets/components/DailyClaim/RigelLine.svg';
import ticketLine from '../../assets/components/DailyClaim/TicketLine.svg';
import crown from '../../assets/components/DailyClaim/Crown.png';
import { claimDailyReward, getDailyRewards } from '../../tools/requests';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import './DailyClaimModal.scss';

const DailyClaimModal = ({ title, dailyRewards, dailyStreak, onClose }) => {
    const userData = window.Telegram.WebApp.initData;
    const dispatch = useDispatch();

    const [newDailyRewards, setNewDailyRewards] = useState([]);
    const currentDayItem = dailyRewards.find((item) => {
        return item.can_be_claimed === true;
    });

    useEffect(() => {
        if (dailyRewards.length > 0) {
            const lastItemWithIcon = {
                ...dailyRewards[dailyRewards.length - 1],
                icon: crown,
            };
            const updatedRewards = [
                ...dailyRewards.slice(0, -1),
                lastItemWithIcon,
            ];

            // Iterate through the array to mark claimed items
            for (let i = 0; i < updatedRewards.length - 1; i++) {
                if (updatedRewards[i].can_be_claimed === false) {
                    updatedRewards[i] = {
                        ...updatedRewards[i],
                        claimed: true, // Mark as claimed
                    };
                } else {
                    break; // Stop the loop when we find can_be_claimed === true
                }
            }
            // Update the state with the modified array
            setNewDailyRewards(updatedRewards);
        }
    }, [dailyRewards]);

    const handleDailyRewardClaim = async () => {
        await claimDailyReward(userData, dispatch);
        setTimeout(async () => {
            await getDailyRewards(userData, dispatch);
        }, 1000);
        onClose();
    };

    return (
        <div className="daily-claim-modal-container">
            <div className="daily-claim-title">
                <span className="title">{title}</span>
            </div>
            <div className="dc-streak-container">
                {dailyStreak > 1 && (
                    <>
                        <span className="dc-streak">{dailyStreak}</span>
                        <span className="dc-substreak">days streak</span>
                    </>
                )}
            </div>
            <div className="dc-current-reward-container">
                <div className="dc-ticket-reward">
                    <img
                        className="dc-ticket-icon"
                        src={ticketIcon}
                        alt="Ticket Icon"
                    />
                    <div className="dc-current-ticket-info">
                        <span className="dc-ticket-amount">
                            +{Number(currentDayItem?.options?.tickets)}
                        </span>
                        <span className="dc-ticket-name">Tickets</span>
                    </div>
                </div>
                <div className="dc-rigel-reward">
                    <img
                        className="dc-rigel-icon"
                        src={rigelIcon}
                        alt="Rigel Icon"
                    />
                    <div className="dc-current-rigel-info">
                        <span className="dc-rigel-amount">
                            +
                            {parseFloat(
                                currentDayItem?.options?.tokens
                            ).toFixed(2)}
                        </span>
                        <span className="dc-rigel-name">RIG</span>
                    </div>
                </div>
            </div>
            <span className="dc-next-rewards">Next Rewards</span>
            <div className="dc-rewards-wrapper">
                <div className="dc-rewards-container">
                    {newDailyRewards.map((item, index) => (
                        <div
                            key={index}
                            className={`dc-reward-item dc-item-${index} ${
                                item.can_be_claimed ? 'dc-reward-active' : ''
                            } ${item.claimed ? 'dc-item-claimed' : ''}`}
                        >
                            {item.icon ? (
                                <div className="dc-reward-icon">
                                    {item.icon && (
                                        <img
                                            src={item.icon}
                                            alt="Reward Icon"
                                        />
                                    )}
                                </div>
                            ) : null}
                            <span className="dc-reward-day">
                                {index === 6
                                    ? `${item?.name}+`
                                    : `${item?.name}`}
                            </span>
                            <div className="dc-reward-tickets">
                                {item.claimed ? (
                                    <FontAwesomeIcon
                                        className="icon"
                                        icon={faCheck}
                                        style={{ color: '#1AB08C' }}
                                    />
                                ) : (
                                    <img
                                        className="dc-reward-tickets-icon"
                                        src={ticketLine}
                                        alt="Tickets"
                                    />
                                )}
                                <span className="dc-reward-tickets-amount">
                                    +{item?.options?.tickets}
                                </span>
                            </div>
                            <div className="dc-reward-rigel">
                                {item.claimed ? (
                                    <FontAwesomeIcon
                                        className="icon"
                                        icon={faCheck}
                                        style={{ color: '#1AB08C' }}
                                    />
                                ) : (
                                    <img
                                        className="dc-reward-rigel-icon"
                                        src={rigelLine}
                                        alt="Rigel"
                                    />
                                )}
                                <span className="dc-reward-rigel-amount">
                                    +
                                    {parseFloat(item?.options?.tokens).toFixed(
                                        2
                                    )}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="dc-confirm-container">
                <Button
                    className="dc-confirm-btn glowing"
                    onClick={() => handleDailyRewardClaim()}
                >
                    Claim
                </Button>
            </div>
        </div>
    );
};

export default DailyClaimModal;
