import React, { useEffect, useRef, useState } from 'react';
import * as PIXI from 'pixi.js';
import {
    Settings,
    Batch,
    Particle,
} from '../../animations/ProgressBarAnimation';
import { Stage, ParticleContainer } from '@pixi/react';
import './ProgressBar.scss';

const particleImg =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACAQMAAABIeJ9nAAAAA1BMVEWW/sxGCbswAAAACklEQVQI12MAAgAABAABINItbwAAAABJRU5ErkJggg==';

// Convert base64 directly to texture:
const particleTexture = PIXI.Texture.from(particleImg);

const config = {
    particles: 500,
    properties: {
        position: true,
        rotation: true,
        scale: true,
        alpha: true,
    },
    emissionRate: 20,
    listeners: [],
    onChange: function (prop, val) {
        this.listeners.forEach((l) => l(prop, val));
    },
};

const ProgressBar = ({ progress, isIncomeIncreaseActive }) => {
    const [emitPositionRange, setEmitPositionRange] = useState({
        minX: 0,
        maxX: 0,
        y: 0,
    });
    const [stageDimensions, setStageDimensions] = useState({
        width: 0, // Numeric width of the Stage
        height: 0, // Height of the Stage
    });
    const progressBarContRef = useRef(null);
    const progressBarRef = useRef(null);
    const succLineRef = useRef(null);

    // Update emit position and stage width dynamically based on the success line width
    useEffect(() => {
        const padding = 0.5 * 16;
        const updateEmitPosition = () => {
            if (succLineRef.current) {
                const succLineWidth =
                    succLineRef.current.getBoundingClientRect().width; // Get success line width
                const rect = progressBarRef.current.getBoundingClientRect();

                // Update the stage width with the success line width
                setStageDimensions({
                    width: progress < 50 ? succLineWidth + 10 : succLineWidth, // Set stage width dynamically to success line width
                    height: rect.height, // Keep height based on progress bar height
                });

                setEmitPositionRange({
                    minX: padding, // Padding
                    maxX: succLineWidth,
                    y: rect.height / 2,
                });
            }
        };

        updateEmitPosition();

        // Resize listener to update on window resizing
        window.addEventListener('resize', updateEmitPosition);
        return () => window.removeEventListener('resize', updateEmitPosition);
    }, [progress]);

    return (
        <div className="progress-bar-container" ref={progressBarContRef}>
            <div className="progress-bar" ref={progressBarRef}>
                <div className="full-line"></div>
                <div
                    ref={succLineRef}
                    className={
                        isIncomeIncreaseActive
                            ? 'success-line-animated'
                            : 'success-line'
                    }
                    style={{
                        width:
                            progress < 50
                                ? `${progress}%`
                                : `calc(${progress}% - 1rem)`,
                    }}
                ></div>
            </div>

            {isIncomeIncreaseActive && (
                <Stage
                    width={stageDimensions.width} // Pass the dynamically set width
                    height={stageDimensions.height} // Pass the height of the progress bar
                    options={{
                        autoDensity: true,
                        backgroundAlpha: 0,
                        transparent: true,
                    }}
                    style={{
                        position: 'fixed',
                        background: 'transparent',
                        backtoundColor: 'transparent',
                    }}
                >
                    <Settings
                        config={config}
                        style={{
                            background: 'transparent',
                            backtoundColor: 'transparent',
                        }}
                    >
                        {(config) => (
                            <ParticleContainer
                                properties={config}
                                alpha={true}
                                style={{
                                    background: 'transparent',
                                    backtoundColor: 'transparent',
                                }}
                            >
                                <Batch
                                    count={config.particles}
                                    component={Particle}
                                    emitPositionRange={emitPositionRange}
                                    emissionRate={config.emissionRate}
                                    maxParticles={config.particles}
                                    texture={particleTexture}
                                    style={{
                                        background: 'transparent',
                                        backtoundColor: 'transparent',
                                    }}
                                />
                            </ParticleContainer>
                        )}
                    </Settings>
                </Stage>
            )}
        </div>
    );
};

export default ProgressBar;
