import {
    useTonConnectModal,
    useIsConnectionRestored,
    useTonConnectUI,
    useTonWallet,
} from '@tonconnect/ui-react';
import { sendCompleteBoost } from '../../tools/requests';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendUserInfo } from '../../tools/requests';
import { beginCell, Cell } from '@ton/ton';
import toast from 'react-hot-toast';
import Button from '../Button/Button';
import './TonSendTxBoost.scss';

const address = process.env.REACT_APP_TON_ADDRESS;

const TonSendTxBoost = ({
    currentBoostId,
    tonAmount,
    userInfo,
    children,
    className,
    onClose,
    callUserInfo,
    setIsPaymentLoading,
    userId,
    itemType,
    itemId,
}) => {
    const dispatch = useDispatch();

    const { close } = useTonConnectModal();
    const wallet = useTonWallet();
    const isConnectionRestored = useIsConnectionRestored();
    const [tonConnectUI] = useTonConnectUI();

    const initData = useSelector((state) => state.telegram.initData);

    const [isInProgress, setIsInProgress] = useState(false);

    useEffect(() => {
        setBtnContent(getTxButtonContent());
    }, [isConnectionRestored, isInProgress, wallet]);

    const getTxButtonContent = () => {
        if (!isConnectionRestored) return 'Loading...';
        if (isInProgress) return 'In progress...';
        return wallet ? `Pay ${tonAmount} TON` : `Connect TON`;
    };

    const [btnContent, setBtnContent] = useState(getTxButtonContent());

    const onSendTx = async () => {
        try {
            if (!wallet) {
                tonConnectUI.openModal();
                return;
            }
            setIsInProgress(true);
            if (setIsPaymentLoading) dispatch(setIsPaymentLoading(true));

            const message = JSON.stringify({
                userId: userId,
                itemType: itemType,
                itemId: itemId,
            });

            const base64message = btoa(message);

            const body = beginCell()
                .storeUint(0, 32)
                .storeStringTail(base64message)
                .endCell();

            const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 360,
                messages: [
                    {
                        address: address,
                        amount: Math.floor(
                            tonAmount.toString() * Math.pow(10, 9)
                        ),
                        payload: body.toBoc().toString('base64'),
                    },
                ],
            };

            const TonResult = await tonConnectUI.sendTransaction(transaction);

            if (TonResult) {
                const hashHex = Cell.fromBase64(TonResult.boc)
                    .hash()
                    .toString('hex');
                const txHashBytes = Buffer.from(hashHex, 'hex');
                const hash = Buffer.from(txHashBytes, 'hex').toString('base64');
                await sendCompleteBoost(
                    initData,
                    userInfo,
                    dispatch,
                    currentBoostId,
                    hash
                ).then(() => {
                    toast.loading(
                        'Transaction is processed, it may take up to 2 minutes.'
                    );
                    setTimeout(() => {
                        if (callUserInfo)
                            sendUserInfo(initData, null, dispatch);
                    }, 1000);
                    onClose();
                    close();
                });
            }
        } catch {
            toast.error('Transaction cancelled :(');
            if (setIsPaymentLoading) dispatch(setIsPaymentLoading(false));
            onClose();
            close();
        }
    };

    return (
        <div className="ton-btn-container">
            <Button
                className={className}
                disabled={!isConnectionRestored || isInProgress}
                onClick={onSendTx}
            >
                {btnContent}
            </Button>
            {children}
        </div>
    );
};

export default TonSendTxBoost;
