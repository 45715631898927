import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    boosts: {},
    products: null,
    openResumeCollectorModal: false,
};

export const boostSlice = createSlice({
    name: 'boost',
    initialState,
    reducers: {
        setBoosts: (state, action) => {
            state.boosts = action.payload;
        },
        setProducts: (state, action) => {
            state.products = action.payload;
        },
        setOpenResumeCollectorModal: (state, action) => {
            state.openResumeCollectorModal = action.payload;
        },
    },
});

export const { setBoosts, setProducts, setOpenResumeCollectorModal } =
    boostSlice.actions;

export default boostSlice.reducer;
