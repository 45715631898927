import React from 'react';
import { useTonAddress } from '@tonconnect/ui-react';
import TonAddressInfo from '../../components/TonAddressInfo/TonAddressInfo';
import tonIcon from '../../assets/components/TonIcon/TonIcon.svg';
import TonSendWithdrawTransaction from '../../components/TonSendTransaction/TonSendWithdrawTransaction';
import './ReferralWithdrawModal.scss';

const ReferralWithdrawModal = ({ userInfo, onClose }) => {
    const address = useTonAddress();

    return (
        <div className="rw-modal-container">
            <span className="rw-description">
                Please select wallet to withdraw your TON income
            </span>
            {address && (
                <div className="rw-ton-info">
                    <TonAddressInfo />
                </div>
            )}
            <div className="rw-payment-info">
                <div className="rw-payment-item">
                    <span className="rw-payment-title">
                        <img
                            className="rw-payment-icon"
                            src={tonIcon}
                            alt="ton icon"
                        ></img>
                    </span>
                    <span className="rw-payment-amount">
                        <span className="rw-payment-amount-value">
                            {userInfo?.ton_balance}
                        </span>
                        <span className="rw-payment-amount-text">TON</span>
                    </span>
                </div>
            </div>
            <TonSendWithdrawTransaction
                tonAmount={userInfo?.ton_balance}
                address={address}
                userId={userInfo.chat_id}
                onClose={onClose}
                className="payment-btn glowing"
            />
        </div>
    );
};

export default ReferralWithdrawModal;
