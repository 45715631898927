import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    sendCompleteTask,
    sendCompleteAchievement,
} from '../../tools/requests.js';
import Button from '../../components/Button/Button';
import Wrapper from '../../layouts/Wrapper/Wrapper';
import Task from '../../components/Task/Task';
import TaskContainer from '../../components/TaskContainer/TaskContainer';
import Achievement from '../../components/Achievement/Achievement';
import AchievementContainer from '../../components/AchievementContainer/AchievementContainer';
import rigelWhiteIcon from '../../assets/components/StarIcon/RigelWhite.svg';
import rigelAchievementIcon from '../../assets/components/MissionIcon/RigelAchivLogo.svg';
import noImgUserIcon from '../../assets/components/UserAvatar/NoImgUserIcon.png';
import rigelLine from '../../assets/components/DailyClaim/RigelLine.svg';
import ticketLine from '../../assets/components/DailyClaim/TicketLine.svg';
import crown from '../../assets/components/DailyClaim/Crown.png';
import bannerGuys from '../../assets/components/MissionIcon/Banner.png';
import noImgIcon from '../../assets/components/NoImgIcon/NoImgIcon.png';
import SuspenseImage from '../../components/SuspenseImage/SuspenseImage.jsx';
import Skeleton from 'react-loading-skeleton';
import Popup from '../../components/Popup/Popup';
import PaymentMethodModal from '../../modals/PaymentMethodModal/PaymentMethodModal.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import './Missions.scss';
import toast from 'react-hot-toast';

const Missions = () => {
    const dispatch = useDispatch();

    const initData = useSelector((state) => state.telegram.initData);
    const userInfo = useSelector((state) => state.telegram.userInfo);
    const userTasks = useSelector((state) => state.mission.tasks);
    const dailyRewards = useSelector((state) => state.daily.dailyRewards);
    const achieves = useSelector((state) => state.mission.achieves);
    const dailyStreak = useSelector((state) => state.daily.dailyStreak);

    const [activeButton, setActiveButton] = useState('Tasks');
    const [openModal, setOpenModal] = useState(false);
    const [newAchievements, setNewAchievements] = useState([]);
    const [isTxCompleted, setTxCompleted] = useState(false);
    const [currentTaskId, setCurrentTaskId] = useState(null);
    const [newDailyRewards, setNewDailyRewards] = useState([]);

    const handleButtonClick = (buttonId) => {
        setActiveButton(buttonId);
    };

    const handleCompleteTask = useCallback(
        async (id) => {
            const result = await sendCompleteTask(
                initData,
                userInfo,
                dispatch,
                id,
                false
            );
            return result;
        },
        [initData, userInfo, dispatch]
    ); // Dependencies list to ensure it changes only when necessary

    const handleCompleteAchievement = useCallback(
        async (id) => {
            const result = await sendCompleteAchievement(
                initData,
                userInfo,
                dispatch,
                id
            );
            return result;
        },
        [initData, userInfo, dispatch]
    );

    useEffect(() => {
        const transformedAchievements = achieves.map((achievementGroup) => ({
            label: achievementGroup.name,
            description: '',
            currentValue: achievementGroup.progress,
            items: achievementGroup.steps.map((step) => ({
                id: step.id,
                reward: step.reward,
                description: step.name,
                completed: step.completed,
                canBeCompleted: step.can_be_completed,
            })),
        }));
        setNewAchievements(transformedAchievements);
    }, [achieves]);

    useEffect(() => {
        const lastItemWithIcon = {
            ...dailyRewards[dailyRewards.length - 1],
            icon: crown,
        };

        let updatedRewards = [...dailyRewards.slice(0, -1), lastItemWithIcon];

        let foundClaimable = false;

        updatedRewards = updatedRewards.map((item) => {
            if (!foundClaimable) {
                if (item.can_be_claimed) {
                    if (item.day_in_streak === 7) {
                        foundClaimable = true;
                        // If it's Day 7, set claimed: true, even if can_be_claimed is false
                        return { ...item, claimed: true };
                    } else {
                        foundClaimable = true;
                        return { ...item, claimed: false };
                    }
                } else {
                    return { ...item, claimed: true };
                }
            } else {
                return { ...item, claimed: false };
            }
        });

        setNewDailyRewards(updatedRewards);
    }, [userInfo, dailyRewards]);

    const handleOpenModal = async () => {
        setOpenModal(true);
    };

    const memoizedTasks = useMemo(() => {
        return Object.keys(userTasks).map((category) => (
            <TaskContainer
                key={category}
                label={category}
                className={`${category.toLowerCase()}-task-group`}
            >
                {userTasks[category].map((task) => (
                    <Task
                        id={task.id}
                        key={task.id}
                        icon={task.icon || noImgIcon}
                        name={task.name}
                        type={task.type}
                        status={task.status}
                        options={task.options}
                        reward={task.reward}
                        completed={task.completed}
                        userInfo={userInfo}
                        setOpenModal={handleOpenModal}
                        setCurrentTaskId={setCurrentTaskId}
                        handleCompleteTask={handleCompleteTask}
                        isTxCompleted={isTxCompleted}
                        setTxCompleted={setTxCompleted}
                        className="task"
                        userTasks={userTasks}
                    />
                ))}
            </TaskContainer>
        ));
    }, [userTasks]);

    const foundTask = useMemo(() => {
        const task = userTasks.Basic.find(
            (task) =>
                task.name === 'Subscribe on channel' && task.type === 'Channel'
        );
        if (task) {
            return task;
        } else return null;
    }, [userTasks]);

    const memoizedDailyRewards = useMemo(() => {
        return newDailyRewards.map((item, index) => (
            <div
                key={index}
                className={`ab-reward-item ab-item-${index} ${
                    item.claimed ? 'ab-item-claimed' : ''
                }`}
            >
                {item.icon && (
                    <div className="ab-reward-icon">
                        <img src={item.icon} alt="Reward Icon" />
                    </div>
                )}
                <span className="ab-reward-day">
                    {index === 6 ? `${item?.name}+` : `${item?.name}`}
                </span>
                <div className="ab-reward-tickets">
                    {item.claimed ? (
                        <FontAwesomeIcon
                            className="icon"
                            icon={faCheck}
                            style={{ color: '#1AB08C' }}
                        />
                    ) : (
                        <img
                            className="ab-reward-tickets-icon"
                            src={ticketLine}
                            alt="Tickets"
                        />
                    )}
                    <span className="ab-reward-tickets-amount">
                        +{item?.options?.tickets}
                    </span>
                </div>
                <div className="ab-reward-rigel">
                    {item.claimed ? (
                        <FontAwesomeIcon
                            className="icon"
                            icon={faCheck}
                            style={{ color: '#1AB08C' }}
                        />
                    ) : (
                        <img
                            className="ab-reward-rigel-icon"
                            src={rigelLine}
                            alt="Rigel"
                        />
                    )}
                    <span className="ab-reward-rigel-amount">
                        +{parseFloat(item?.options?.tokens).toFixed(2)}
                    </span>
                </div>
            </div>
        ));
    }, [newDailyRewards]);

    const memoizedAchievements = useMemo(() => {
        return newAchievements.map((achievement, index) => (
            <AchievementContainer
                key={index}
                className={`${achievement.label
                    .toLowerCase()
                    .replace(' ', '-')}-group`}
                label={achievement.label}
                description={achievement.description}
                currentValue={achievement.currentValue}
            >
                {achievement.items.map((item, itemIndex) => (
                    <Achievement
                        id={item.id}
                        key={itemIndex}
                        reward={item.reward}
                        icon={rigelAchievementIcon}
                        description={item.description}
                        className={`achievement-${itemIndex}`}
                        completed={item.completed}
                        canBeCompleted={item.canBeCompleted}
                        handleCompleteAchievement={handleCompleteAchievement}
                    />
                ))}
            </AchievementContainer>
        ));
    }, [newAchievements]);

    const handleBannerRedirect = (foundTask) => {
        if (foundTask.status === 'Available') {
            console.log('FoundTask', foundTask);
            toast('Moving to channel!');
            window.Telegram.WebApp.openTelegramLink(
                foundTask.options.redirect_to
            );
            setTimeout(() => {
                toast('Confirming...\n');
                handleCompleteTask(foundTask.id);
            }, 1000);
        } else {
            toast('Moving to channel!');
            window.Telegram.WebApp.openTelegramLink(
                foundTask.options.redirect_to
            );
        }
    };

    return (
        <Wrapper>
            <div className="mission-container">
                <div className="user-info">
                    <SuspenseImage
                        className="user-avatar"
                        src={userInfo.photo_url || noImgUserIcon}
                        alt="user avatar"
                        width="8rem"
                        height="8rem"
                    ></SuspenseImage>
                    <div className="user-data">
                        <span className="user-name">
                            {userInfo?.first_name || <Skeleton />}
                        </span>
                        <div className="user-coins">
                            <SuspenseImage
                                className="diamond"
                                src={rigelWhiteIcon}
                                alt="diamond icon"
                                style={{ width: '2rem' }}
                                width="2rem"
                                height="2rem"
                            ></SuspenseImage>
                            <span className="coins">
                                {parseFloat(userInfo?.balance).toFixed(4) || (
                                    <Skeleton />
                                )}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="list-sort-group">
                    <Button
                        className={`sort-btn ${
                            activeButton === 'Tasks' ? 'active' : ''
                        }`}
                        onClick={() => handleButtonClick('Tasks')}
                    >
                        Tasks
                    </Button>
                    <Button
                        className={`sort-btn ${
                            activeButton === 'achievements' ? 'active' : ''
                        }`}
                        onClick={() => handleButtonClick('achievements')}
                    >
                        Achievements
                    </Button>
                </div>

                {activeButton === 'Tasks' && (
                    <div className="eni-tasks-block">
                        {foundTask && (
                            <Button
                                onClick={() => handleBannerRedirect(foundTask)}
                                className="eni-task-banner"
                            >
                                <div className="eni-task-banner-bg">
                                    <div className="taks-bg-1"></div>
                                    <img
                                        src={bannerGuys}
                                        alt="banner-guys"
                                        className="task-banner-guys"
                                    ></img>
                                </div>
                                <span className="task-banner-header">
                                    Join our community
                                </span>
                                <span className="task-banner-description">
                                    To follow the project news and participate
                                    in events
                                </span>
                                {foundTask.status === 'Available' && (
                                    <div className="task-banner-reward">
                                        <span className="task-banner-reward-get">
                                            Get
                                        </span>
                                        <span className="task-banner-reward-rig">
                                            <img
                                                src={rigelWhiteIcon}
                                                alt="rigel icon"
                                            ></img>
                                            {parseFloat(foundTask.reward).toFixed(4)} RIG
                                        </span>
                                    </div>
                                )}
                            </Button>
                        )}
                        <div className="eni-tasks">{memoizedTasks}</div>
                    </div>
                )}

                {activeButton === 'achievements' && (
                    <div className="achievements-block">
                        <div></div>
                        <div className="mc-achievement-info">
                            <label className="mc-achievement-label">
                                Daily Reward
                            </label>
                            <div className="mc-description-block">
                                <span className="mc-achievement-value">
                                    {dailyStreak || <Skeleton />} Days streak
                                </span>
                            </div>
                        </div>
                        <div className="ab-rewards-wrapper">
                            <div className="ab-rewards-container">
                                {dailyRewards && memoizedDailyRewards}
                            </div>
                        </div>
                        {memoizedAchievements}
                    </div>
                )}
            </div>
            <Popup
                title="Choose payment method"
                className="payment-popup"
                open={openModal}
                onClose={() => setOpenModal(false)}
                style={{ zIndex: 1000 }}
            >
                {
                    <PaymentMethodModal
                        currentTaskId={currentTaskId}
                        userInfo={userInfo}
                        setTxCompleted={setTxCompleted}
                        onClose={() => setOpenModal(false)}
                    />
                }
            </Popup>
        </Wrapper>
    );
};

export default Missions;
