import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dailyRewards: [],
    dailyStreak: 0,
};

export const dailySlice = createSlice({
    name: 'daily',
    initialState,
    reducers: {
        setDailyRewards: (state, action) => {
            state.dailyRewards = action.payload;
        },
        setDailyRewardStreak: (state, action) => {
            state.dailyStreak = action.payload;
        },
    },
});

export const { setDailyRewards, setDailyRewardStreak } = dailySlice.actions;

export default dailySlice.reducer;
