import React, { useState } from 'react';
import toncoinIcon from '../../assets/components/TonIcon/TonIcon.svg';
import rigelTripleIcon from '../../assets/components/StarIcon/RigelTriple.svg';
import { useTonAddress } from '@tonconnect/ui-react';
import TonAddressInfo from '../../components/TonAddressInfo/TonAddressInfo';
import TonSendTxBoost from '../../components/TonSendTransaction/TonSendTxBoost';
import Button from '../../components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { buyProductWithTgStars, sendUserInfo } from '../../tools/requests';
import toast from 'react-hot-toast';
import { paymentAction } from '../../store/reducers';
import './X2PaymentClaimModal.scss';

const X2PaymentClaimModal = ({
    starsAmount,
    tonAmount,
    dollarAmount,
    onClose,
}) => {
    const dispatch = useDispatch();
    const address = useTonAddress();
    const tg = window.Telegram.WebApp;
    const initData = useSelector((state) => state.telegram.initData);
    const userInfo = useSelector((state) => state.telegram.userInfo);
    const products = useSelector((state) => state.boost.products);
    const x2ClaimProducts = products.RateClaim.levels;
    const rateClaimProduct = x2ClaimProducts[0];

    const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);

    const handleStarsPayment = async () => {
        if (isInvoiceOpen) {
            toast('Only one payment can be processed at the same time..');
            return;
        }

        dispatch(paymentAction.setIsPaymentLoading(true));
        setIsInvoiceOpen(true);

        const result = await buyProductWithTgStars(
            userInfo.chat_id,
            rateClaimProduct.id,
            initData,
            starsAmount
        );
        if (result && result.url) {
            tg.openInvoice(result.url, (invoiceStatus) => {
                if (invoiceStatus === 'paid') {
                    onClose();
                    setIsInvoiceOpen(false);
                    setTimeout(() => {
                        sendUserInfo(initData, null, dispatch);
                    }, 2000);
                }
                if (invoiceStatus === 'cancelled') {
                    setIsInvoiceOpen(false);
                }
                if (invoiceStatus === 'failed') {
                    setIsInvoiceOpen(false);
                }
            });
        } else {
            console.error('Invoice URL is invalid', result);
            setIsInvoiceOpen(false);
        }
    };

    return (
        <div className="payment-claim-container">
            <span className="confirm-buy-label">Chosen Option</span>
            <div className="confirm-payment-claim-container">
                <div className="choosen-payment-claim-info">
                    <div className="boost-img-container">
                        <img src={rigelTripleIcon} alt="auto collector icon" />
                    </div>
                    <div className="payment-claim-info">
                        <span className="label">Double Reward</span>
                    </div>
                </div>
                <div className="payment-claim-right-part">
                    <div className="ton-cost">
                        <img src={toncoinIcon} alt="ton coin icon" />
                        <span>{tonAmount}</span>
                    </div>
                </div>
            </div>
            <div className="confirm-pay-container">
                <div className="modal-container">
                    {address && (
                        <div className="ton-info">
                            <TonAddressInfo />
                        </div>
                    )}
                    <div className="payment-info">
                        <div className="info-value">
                            <span className="before-payment">
                                {dollarAmount} $ = {tonAmount} TON
                            </span>
                        </div>
                    </div>
                    <TonSendTxBoost
                        tonAmount={tonAmount}
                        currentBoostId={rateClaimProduct.id}
                        className="payment-btn glowing"
                        userInfo={userInfo}
                        onClose={onClose}
                        callUserInfo={true}
                        setIsPaymentLoading={paymentAction.setIsPaymentLoading}
                        userId={userInfo.chat_id}
                        itemType={'product'}
                        itemId={rateClaimProduct.id}
                    />
                    <Button
                        className="payment-btn glowing"
                        onClick={handleStarsPayment}
                    >
                        Pay {starsAmount} Stars
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default X2PaymentClaimModal;
